<template>
    <div v-if="isShow" class="bidding-limit" v-cloak>
        <div :class="[{'rs-card_simple':isTop === 1, open: !isCalcHide}]" class="js-inv-vehicles" >
            <div :class="[{'bidding-limit__header':isTop !== 1 }]">
                <div v-if="isTop === 1" class="rs-card_simple__header-container ">
                    <div class="flex items-center">
                        <span>
                            <img width="48" height="48" src="/images/money_icon.svg?nr=1" class="icon" />
                        </span>
                        <h3 class="rs-card_simple__header">{{ t(509, 'Bidding limit') }} &amp; {{ t(738, 'Deposit Information') }}</h3>
                    </div>
                    <div class="rs-card_simple__devider"></div>
                </div>
                <h3 v-if="isTop !== 1" class="border-title-small">{{ t(509, 'Bidding limit') }} &amp; {{ t(738, 'Deposit Information') }}</h3>
            </div>
            <div ref="biddingLimit" :style="{ height: biddingLimitHight + 'px' }">
                <div class="bid-auction-limit bidding-limit__content">
                    <h5 class="">
                        {{ t(508, 'Remaining Bidding Limit') }}:
                        <span> {{ rCapC }}</span>
                    </h5>
                    <p>{{ t(1426, 'Bidding limit - the maximum amount you expect to spend on auction day.') }}</p>
                    <p>{{ t(1427, '	Bidding limit is required to activate a bid. Bidding limit changes based on your current activity such as active bids, pending auction results or unpaid open invoice.') }}</p>
                    <p class="rs-mb-0">
                        <span>{{ t(1430, 'There is no fee to register or bid online. However, you are required to place a refundable bid deposit equaling') }}</span> 
                        <span id="ctl01_lblBiddingLimitDescAmount"> ${{MINDEPAMOUNT}} USD </span>
                        <span>{{ t(1428, 'or') }}</span>
                        <span id="ctl01_lblBiddingLimitDescPercent">&nbsp;{{MINDEPPERCENT}}%, </span> 
                        <span>{{ t(1429, 'of your maximum bid (whichever is greater).') }}</span>
                    </p>
                </div>

                <div class="bidding-limit__content">
                    <div class="bid-auction-limit">
                        <div class="flex">
                            <input type="text" v-model="desiredLimit" class="calculator-input number-only" v-on:input="calcInputInputHandler" v-on:focus="calcInputFocusHandler" v-on:blur="calcInputBlurHandler"  >
                            <button type="submit" class="rs-btn rs-btn_md rs-btn_primary" v-on:click="calculate">{{ t(817, 'Calculate') }}</button>
                        </div>
                    </div>

                    <div class="bid-auction-limit" v-if="isShowDepositInfo">
                        <h5 class="margin-tb">
                            <span>
                                {{ t(816, 'Required Deposit:') }}
                            </span>
                            <span class="rs-ml-2"> {{ requiredDeposit.requiredDepositC }}</span>
                        </h5>

                        <div class="flex justify-between rs-mb-4 ccd">
                            <a class="rs-btn rs-btn_sm rs-btn_default rs-btn_with-icon--right" :href="ccPaymentUrl">
                                {{ t(815, 'Make Credit Card Deposit Now') }}
                                <span>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.66667 2.33325L9.33334 6.99992L4.66667 11.6666" stroke="#666666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>                        
                                </span>    
                            </a>
                            <img height="25px" class="rs-size__desk" src="/images/cards.png">
                        </div>
                    </div>
                </div>

                <div v-if="!isHeaderCalculator" class="home-viewall">
                    <div class="rs-btn rs-btn_sm rs-btn_default rs-btn_with-icon--right home-viewall__btn" @click="openCalcHandler" ref="openCalcBtn">
                        <span>{{isCalcHide ? 'Increase Limit' : 'Hide Calculator'}}</span>
                        <span>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.25 13.5L6.75 9L11.25 4.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>                        
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from '../components/toast';
import { rsTranslator } from '../mixins/translate';
import axios from 'axios';
import Format from '../utilities/formats';

const LANG = document.querySelector('#rsLang').value;
const rsTotalCapC = document.querySelector('#rsTotalCapC').value;
const officeEmail = document.querySelector('#rsOfficeEmail').value;
const MINDEPAMOUNT = document.querySelector('#rsMinDepositAmount').value;
const MINDEPPERCENT = document.querySelector('#rsMinDepositPercent').value;
const MINBIDVALUE = document.querySelector('#rsMinBidLabelNumber')?.value;
const IID = document.querySelector('#rsInventoryId')?.value;
const miscInventoryId = document.querySelector('#miscInventoryId')?.value;
const PROPOSE_VALUE = document.querySelector('#js-bid-default-value')?.value;

// Translates
const format = new Format()

// const inventoryId = document.querySelector('#rsTotalCapC').value;

    // export const calcComponent = Vue.createApp({
export default {
    name: "calculator",
    mixins: [ rsTranslator ],
    props: {
        isTop: {
            type: Number
        }
    },
    data() {
        return {
            isShow: false,
            isCalcHide: true,
            isHeaderCalculator: false,
            isShowDepositInfo: false,
            biddingLimitHight: 83,
            calcBlockHight: null,
            inventoryId: null,
            currLang: 'en',
            rCapC: null,
            officeEmail: '',
            newProposeValue: '',
            wasChanged: false,
            desiredLimit: null,
            requiredDeposit: {},
            ccPaymentUrl: "",
            MINDEPAMOUNT: MINDEPAMOUNT,
            MINDEPPERCENT: MINDEPPERCENT
        }
    },
    created: async function() {
        this.inventoryId = IID
            ? IID
            : miscInventoryId;

        this.isShow = true

        this.officeEmail = officeEmail
        this.rCapC = rsTotalCapC

        this.isHeaderCalculator = IID && Number(this.isTop) === 1 ? false : true;
        this.biddingLimitHight = this.isHeaderCalculator ? null : 83

        if(IID && Number(this.isTop) === 0) {
            this.desiredLimit = PROPOSE_VALUE;
            this.newProposeValue = PROPOSE_VALUE;
        }
    },
    methods: {
        openCalcHandler() {
            const biddingLimitBlock = this.$refs.biddingLimit
            const openCalcBtn = this.$refs.openCalcBtn
            biddingLimitBlock.style.height = `auto`;
            const hidingBlockHeight = biddingLimitBlock.getBoundingClientRect().height;

            if(!this.calcBlockHight)
                this.calcBlockHight = biddingLimitBlock.getBoundingClientRect().height;
    
            if(this.isCalcHide) {
                this.isCalcHide = false 
                this.biddingLimitHight = hidingBlockHeight;
                // this.biddingLimitHight = 'auto'
                biddingLimitBlock.style.height = `auto`;
                openCalcBtn.style.display = 'none';
            } else {
                this.isCalcHide = true
                this.biddingLimitHight = 83
            }
        },
        async calculate() {
            this.wasChanged = false;

            if(Number(this.isTop) === 1 && !this.desiredLimit ) {
                console.log('return')
                return;
            }

            if(Number(this.isTop) === 0 && !this.desiredLimit ) {
                console.log('return')
                return;
            }

            // if (Number(this.desiredLimit) < Number(PROPOSE_VALUE)) {
            //     this.newProposeValue = PROPOSE_VALUE;
            //     this.desiredLimit = PROPOSE_VALUE;
            // } else {
            //     this.newProposeValue = this.desiredLimit;
            // }

            if(IID) {
                console.log("🚀 ~ file: calculator.js:110 ~ calculate ~ (Number(MINBIDVALUE)", Number(MINBIDVALUE));
                if(Number(MINBIDVALUE) > this.desiredLimit) {
                    this.desiredLimit = Number(MINBIDVALUE);
                    
                    new Toast({
                        message: `<h3 class="rs-mb-2">${this.t(729, 'Bid Correction!')}</h3> 
                        ${this.t(730, 'Your minimum bid should be')} ${format.money(Number(MINBIDVALUE), LANG)} ${this.t(731, 'or more.')}`,
                        type: 'warning',
                        confirm: true,
                        customButtons: [{text: 'OK'}]
                    });

                    return;
                }
            }
            const _updateRequireDeposit = (requiredDeposit, customerCapC, customerCap ) => {

                // if(!this.requiredDeposit?.isSuccess) {
                //     this.desiredLimit = this.requiredDeposit.proposedBid;
                //     this.newProposeValue = this.requiredDeposit.proposedBid;
                // }
                
                if(!this.requiredDeposit?.isSuccess && IID) { // If is inventory
                    this.desiredLimit = this.requiredDeposit.proposedBid;
                    this.isShowDepositInfo = false;

                    new Toast({
                        message: !this.requiredDeposit.errorMsg ? `<h3 class="rs-mb-2">${this.t(729, 'Bid Correction!')}</h3> ${this.requiredDeposit?.message}` : `<h3 class="rs-mb-2">${this.t(729, 'Bid Correction!')}</h3> 
                        <div>
                        <ul style="list-style:inside">
                            <li>${this.requiredDeposit?.message}</li>
                            <li>${this.requiredDeposit?.errorMsg}</li>
                        </ul>
                        </div>`,
                        type: 'warning',
                        confirm: true,
                        customButtons: [{text: 'OK'}]
                    });
                    
                } else if(!this.requiredDeposit?.isSuccess && !IID) { // If is not inventory
                    this.desiredLimit = this.requiredDeposit.proposedBid;
                    this.isShowDepositInfo = false;

                    new Toast({
                        message: !this.requiredDeposit.errorMsg ? `<h3 class="rs-mb-2">${this.t(729, 'Bid Correction!')}</h3> ${this.requiredDeposit?.message}` : `<h3 class="rs-mb-2">${this.t(729, 'Bid Correction!')}</h3> 
                        <div>
                        <ul style="list-style:inside">
                            <li>${this.requiredDeposit?.message}</li>
                            <li>${this.requiredDeposit?.errorMsg}</li>
                        </ul>
                        </div>`,
                        type: 'warning',
                        confirm: true,
                        customButtons: [{text: 'OK'}]
                    });
                    
                } else if ( this.desiredLimit <= customerCap ) {
                    this.isShowDepositInfo = false;
                    const title = `${this.t(523, 'Your Bidding Limit is')} ${customerCapC}`;
                    new Toast({
                        message: `<h3 class="rs-mb-2">${title}</h3> ${this.t(524, 'You have enough credit limit and you don\'t have to send another deposit.')}`,
                        type: 'success',
                        confirm: true,
                        customButtons: [{text: 'OK'}]
                    });

                    setTimeout(() => {
                        this.isShowDepositInfo = false
                        // this.isShowWireInfo = false
                        // this.desiredLimit = this.newProposeValue;
        
                    },2000)

                    return;
                }

                if (this.desiredLimit > customerCap && this.desiredLimit > 0) {

                    console.log('Calculate OK!');
                    this.isShowDepositInfo = true;
                    this.desiredLimit = this.requiredDeposit.proposedBid;
                    this.newProposeValue = this.requiredDeposit.proposedBid;
                    return; 
                } 

            }

            const setBiddingData = async (data) => {
                if(IID && Number(this.isTop) === 1) {
                    const biddingLimitBlock = await this.$refs.biddingLimit;
                    this.biddingLimitHight = null;
                    
                    biddingLimitBlock.style.height = `auto`;
                }
                
                this.requiredDeposit = await {...data.requiredDeposit};
                this.wireInfo = await {...data.wireInfo};
                this.ccPaymentUrl = await data.ccPaymentUrl;

                _updateRequireDeposit(
                    data.requiredDeposit.requiredDeposit, 
                    data.requiredDeposit.customerCapC, 
                    data.requiredDeposit.customerCap )
            }
            
            const _url = IID
                            ? `./bidding/getrequireddepositandwireinfo?amount=${this.desiredLimit}&inventoryid=${this.inventoryId}&nr=1`
                            : `./bidding/getrequireddepositandwireinfo?amount=${this.desiredLimit}&nr=1`
                            
            axios.get(_url)
                .then( res => {
                    if(!res?.data) 
                        return;

                    setBiddingData(res.data);

                })
                .catch( err => console.error(err) )

            
        
        },
        calcInputFocusHandler() {
            console.log('Was Changed: ', this.wasChanged)
            if(!IID) {
                this.desiredLimit = this.desiredLimit == this.defaultValue ? '' :  this.desiredLimit
                return
            }

            if(Number(this.isTop) === 0) {
                // this.desiredLimit = this.desiredLimit == this.defaultValue ? '' :  this.desiredLimit;
                if (!this.wasChanged) this.desiredLimit =  '';
            }
        },
        calcInputBlurHandler() {
            if(!IID) {
                this.desiredLimit = !this.desiredLimit ? this.defaultValue : this.desiredLimit;
                return
            }
            if(Number(this.isTop) === 0 ) {
                // this.desiredLimit = !this.desiredLimit ? this.defaultValue : this.desiredLimit;
                if (!this.desiredLimit && !this.wasChanged && this.newProposeValue) {
                    this.desiredLimit = this.newProposeValue;
                    return;
                }
                
                if (!this.desiredLimit && !this.wasChanged) {
                    this.desiredLimit = PROPOSE_VALUE;
                    return;
                }

            }

        },
        calcInputInputHandler() {
            this.wasChanged = true;
        }
    },
    computed: {
        // inventoryId: function () {
        //     return miscInventoryId;
        // },
        bankWireNote: function () {
            let s = `Buyer Number: ${this.wireInfo.customerId}`;
            if (!this.inventoryId)
                return s;
            if (this.inventoryId !== miscInventoryId)
                s = `Buyer Number: ${this.wireInfo.customerId}; Inventory Number: ${this.inventoryId}`;
                return s;
        },
        defaultValue: function() {
            if(!IID) {
                return 500;
            }
            if(MINBIDVALUE > 500) {
                return MINBIDVALUE;
            } else {
                return 500;
            }
        }
    }
}

</script>