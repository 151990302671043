window.addEventListener('load', (e) => {
    if (!document.querySelector('.h-adv-search__container')) {
        return;
    }

    const clearModelField = () => {
        const selectedMake = makesField.querySelector('.js-suggestions__list').value;
        const makeIdx = mk.findIndex(m => m === selectedMake);
        const models = m2[makeIdx];

        if (modelsField?.querySelectorAll('.js-suggestions__list option').length) {
            modelsField?.querySelectorAll('.js-suggestions__list option').forEach((el, i) => {
                el.value !== '' && el.remove();
                modelsField.querySelector('.js-suggestions__list').value = 'ALL';
                // el && createOptions(el, i, modelsField.querySelector('.js-suggestions__list'));
            });
        }
    }

    //const closeDropDown = (type) => {
    //    const currClass = `js-adv-search__${type}`;
    //    document.querySelectorAll('.auto-complete-container').forEach(el => {
    //        if (!el.classList.contains(currClass)) {
    //            el.classList.remove('open');
    //        }
    //    })
    //}

    //const openClassAdd = (el) => {
    //    const parent = el.closest('.form-group');
    //    parent.classList.add('open');

    //    // const suggestions = parent.querySelector('.js-suggestions__list');
    //    // suggestions.style.display = 'block';

    //}

    //const openClassRemove = (el) => {
    //    const parent = el.closest('.form-group');
    //    parent.classList.remove('open');
    //}

    const selectMake = (make) => {
        console.log('selectMake')
        const makeIdx = mk.findIndex(m => m === make);
        const models = m2[makeIdx];

        if (cmk !== make) {
            cml = '';
            clearModelField();
        }

        if (!models || !models?.length) {
            clearModelField();
            // modelsField.querySelector('.js-suggestions__input').disabled = true;
            return;
        }

        if (models?.length) {
            models.forEach((el, i) => {
                const se = el && el.split('|')[0];
                createOptions(se, i, modelsField.querySelector('.js-suggestions__list'));

                // modelsField.querySelector('.js-suggestions__input').disabled = false;
            });
        }
    }

    const Y_FROM = Number(document.querySelector('#hdnYFrom')?.value);
    const Y_TO = 1901;

    const yearsRange = Array.from({ length: Y_FROM - Y_TO }, (_, i) => Y_FROM - i);

    //const autoCompleteInputs = document.querySelectorAll('.js-suggestions__input');
    //const dateRangeFields = document.querySelectorAll('.js-suggestions__field');

    //const suggestionsList = document.querySelectorAll('.js-suggestions__list');
    const makesField = document.querySelector('.js-adv-search__make');
    const modelsField = document.querySelector('.js-adv-search__model');
    const yearFromField = document.querySelector('.js-adv-search__yfrom');
    const yearToField = document.querySelector('.js-adv-search__yto');

    // Current make & model varibles
    let cmk = '';
    let cml = '';
    let cty = '';
    let ctf = '';

    // Make array
    const mk = m1;

    const createOptions = (options, idx, select) => {
        const option = document.createElement('option');
        option.textContent = options;

        if (idx !== 0) option.dataset.id = idx;

        select.appendChild(option);
    };

    const init = () => {

        // // Init make field
        // createOptions('ALL', 0, makesField.querySelector('.js-suggestions__list'));

        // // Init model field
        // createOptions('ALL', 0, modelsField.querySelector('.js-suggestions__list'));
        // modelsField.querySelector('.js-suggestions__input').disabled = true;

        // // Init 'from year' field
        // createOptions('ANY', 0, yearFromField.querySelector('.js-suggestions__list'));

        // // Init 'to year' field
        // createOptions('ANY', 0, yearToField.querySelector('.js-suggestions__list'));

        mk.forEach((el, i) => {
            createOptions(el, i, makesField.querySelector('.js-suggestions__list'));
        });

        makesField.querySelector('.js-suggestions__list').addEventListener('change', function (e) {
            const selectedMake = e.target.value;
            console.log('selectedMake', selectedMake)
            selectMake(selectedMake);
            cmk = selectedMake;
        });

        yearsRange.forEach((el, i) => {
            createOptions(el, i, yearToField.querySelector('.js-suggestions__list'));
            createOptions(el, i, yearFromField.querySelector('.js-suggestions__list'));
        });

    }

    // Init advanced search
    init();

    // autoCompleteInputs.forEach( inp => {
    //     const cst = inp.closest('.auto-complete-container')
    //     inp.value = Array.from(cst.querySelector('.js-suggestions__list').childNodes)[0].innerText;
    // })

    const makesList = makesField.querySelector('.js-suggestions__list').childNodes;
    const makeSuggestions = Array.from(makesList).map(el => el.innerText);

    //autoCompleteInputs.forEach(inp => {
    //    inp.addEventListener('input', function () {
    //        const input = inp.value.toLowerCase();
    //        const inputContainer = inp.closest('.auto-complete-container');

    //        if (input === 'all' || input === 'any') {
    //            inp.value = '';
    //        }

    //        if (inputContainer.classList.contains('js-adv-search__make')) {
    //            if (input) {
    //                const filteredSuggestions = makeSuggestions.filter(s => s && s.toLowerCase().includes(input));

    //                makesField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //                    item.style.display = filteredSuggestions.includes(item.textContent) ? 'block' : 'none';

    //                    item.addEventListener('click', function () {
    //                        inp.value = item.textContent;
    //                        openClassRemove(inp);
    //                    });

    //                    inputContainer.querySelector('.js-suggestions__list').appendChild(item);
    //                });

    //            } else {
    //                openClassAdd(inp);
    //            }
    //        }

    //        if (inputContainer.classList.contains('js-adv-search__model')) {
    //            const selectedMake = makesField.querySelector('.js-suggestions__input').value;
    //            const makeIdx = mk.findIndex(m => m === selectedMake);
    //            const models = m2[makeIdx];

    //            if (input) {
    //                const filteredSuggestions = models.filter(s => s.toLowerCase().includes(input));

    //                modelsField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //                    const updatedFilteredSuggestions = filteredSuggestions.map(el => el.split('|')[0]);
    //                    item.style.display = updatedFilteredSuggestions.includes(item.textContent) ? 'block' : 'none';

    //                    item.addEventListener('click', function () {
    //                        inp.value = item.textContent;

    //                        openClassRemove(inp);
    //                    });

    //                    inputContainer.querySelector('.js-suggestions__list').appendChild(item);
    //                });

    //            } else {
    //                console.log('No input... ', models.filter(s => s.toLowerCase().includes(input)))
    //                const filteredSuggestions = models.filter(s => s.toLowerCase().includes(input));

    //                modelsField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //                    item.style.display = 'block';

    //                    item.addEventListener('click', function () {
    //                        inp.value = item.textContent;

    //                        openClassRemove(inp);
    //                    });

    //                    inputContainer.querySelector('.js-suggestions__list').appendChild(item);
    //                });
    //                openClassAdd(inp);
    //            }
    //        }

    //        // if (inputContainer.classList.contains('js-adv-search__yfrom')) {
    //        //     if (input) {
    //        //         // const filteredSuggestions = yearsRange.filter(s => s.toString().includes(input));
    //        //         const filteredSuggestions = yearsRange;

    //        //         yearFromField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //        //             if (filteredSuggestions.includes(Number(item.textContent))) {
    //        //                 item.style.display = 'block';
    //        //             } else {
    //        //                 item.style.display = 'none';
    //        //             }

    //        //             item.addEventListener('click', function () {
    //        //                 inp.innerText = item.textContent;

    //        //                 openClassRemove(inp);

    //        //             });

    //        //             inputContainer.querySelector('.js-suggestions__list').appendChild(item);
    //        //         });

    //        //     } else {
    //        //         openClassAdd(inp);
    //        //     }
    //        // }

    //        // if (inputContainer.classList.contains('js-adv-search__yto')) {
    //        //     if (input) {
    //        //         // const filteredSuggestions = yearsRange.filter(s => s.toString().includes(input));
    //        //         const filteredSuggestions = yearsRange;

    //        //         yearToField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //        //             if (filteredSuggestions.includes(Number(item.textContent))) {
    //        //                 item.style.display = 'block';
    //        //             } else {
    //        //                 item.style.display = 'none';
    //        //             }

    //        //             item.addEventListener('click', function () {
    //        //                 inp.innerText = item.textContent;

    //        //                 openClassRemove(inp);
    //        //             });

    //        //             inputContainer.querySelector('.js-suggestions__list').appendChild(item);
    //        //         });

    //        //     } else {
    //        //         openClassAdd(inp);
    //        //     }
    //        // }
    //    });

    //    const selMakeHandler = (item) => {
    //        const value = item.textContent;
    //        inp.value = value;
    //        selectMake(value);
    //        cmk = value;
    //        suggestionsList.forEach(el => openClassRemove(inp)); // Hide suggestions after selection
    //    };


    //    if (inp.closest('.js-adv-search__make')) {
    //        makesField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //            item.addEventListener('click', () => selMakeHandler(item))
    //        })
    //    }

    //    inp.addEventListener('focus', function () {
    //        const input = inp.value.toLowerCase();
    //        const inputContainer = inp.closest('.auto-complete-container');

    //        if (input === 'all' || input === 'any') {
    //            inp.value = '';
    //        }

    //        if (inputContainer.classList.contains('js-adv-search__make')) {
    //            openClassAdd(inp);
    //            closeDropDown('make');

    //            return;
    //        };

    //        if (inputContainer.classList.contains('js-adv-search__model')) {
    //            openClassAdd(inp);
    //            closeDropDown('model');

    //            modelsField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //                item.addEventListener('click', function () {
    //                    // Set input field value
    //                    inp.value = item.textContent;
    //                    cml = item.textContent;

    //                    suggestionsList.forEach(el => openClassRemove(inp)); // Hide suggestions after selection

    //                })
    //            })

    //            return
    //        }

    //        // if (inputContainer.classList.contains('js-adv-search__yfrom')) {
    //        //     openClassAdd(inp);
    //        //     closeDropDown('yfrom');

    //        //     yearFromField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //        //         item.addEventListener('click', function () {
    //        //             // Set input field value
    //        //             inp.value = item.textContent;
    //        //             ctf = item.textContent;

    //        //             suggestionsList.forEach(el => openClassRemove(inp)); // Hide suggestions after selection

    //        //         })
    //        //     })

    //        //     return
    //        // }

    //        // if (inputContainer.classList.contains('js-adv-search__yto')) {
    //        //     openClassAdd(inp);
    //        //     closeDropDown('yto');

    //        //     yearToField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //        //         item.addEventListener('click', function () {
    //        //             // Set input field value
    //        //             inp.value = item.textContent;
    //        //             cty = item.textContent;

    //        //             suggestionsList.forEach(el => openClassRemove(inp)); // Hide suggestions after selection

    //        //         })
    //        //     })

    //        //     return
    //        // }


    //    });
    //});

    //dateRangeFields.forEach(inp => {
    //    inp.addEventListener('click', function () {
    //        const input = inp.innerText.toLowerCase();
    //        const inputContainer = inp.closest('.auto-complete-container');


    //        if (input === 'all' || input === 'any') {
    //            inp.value = '';
    //        }

    //        if (inputContainer.classList.contains('js-adv-search__yfrom')) {
    //            document.querySelector('.js-adv-search__yto').classList.remove('open');

    //            if (input) {
    //                const filteredSuggestions = yearsRange;
    //                openClassAdd(inp);

    //                yearFromField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //                    item.addEventListener('click', function () {
    //                        inp.innerText = item.textContent;

    //                        openClassRemove(inp);
    //                    });

    //                    inputContainer.querySelector('.js-suggestions__list').appendChild(item);
    //                });

    //            } else {
    //                openClassAdd(inp);
    //            }
    //        }

    //        if (inputContainer.classList.contains('js-adv-search__yto')) {
    //            document.querySelector('.js-adv-search__yfrom').classList.remove('open');

    //            if (input) {
    //                const filteredSuggestions = yearsRange;
    //                openClassAdd(inp);

    //                yearToField.querySelectorAll('.js-suggestions__list li').forEach(item => {
    //                    item.addEventListener('click', function () {
    //                        inp.innerText = item.textContent;

    //                        openClassRemove(inp);
    //                    });

    //                    inputContainer.querySelector('.js-suggestions__list').appendChild(item);
    //                });

    //            } else {
    //                openClassAdd(inp);
    //            }
    //        }
    //    });
    //});

    const submitAdvSearchHandler = () => {
        // Add loading to button
        const sbtBtn = document.querySelector('.js-adv__submit');
        const spinner = document.createElement('span');
        spinner.classList.add('rs-loader--3');
        sbtBtn.disabled = true;
        sbtBtn.classList.add('loading');
        sbtBtn.insertBefore(spinner, document.querySelector('.js-adv__submit span'));

        const make = makesField.querySelector('.js-suggestions__list').value;
        const model = modelsField.querySelector('.js-suggestions__list').value;
        const yearFrom = yearFromField.querySelector('.js-suggestions__list').value;
        const yearTo = yearToField.querySelector('.js-suggestions__list').value;

        let route = `./salvage-auto-auction-search`;

        const routeStrOptimize = (str) => {
            //return str.replace(/ /g, '_').replace(/\//g, '_');
            return encodeURIComponent(str).replace(/%20/g, '+');
        }

        if (make && make !== 'ALL') {
            route += model && model !== 'ALL'
                ? `/${make.replace(/ /g, '-').replace(/\//g, '-_-') }/`
                : `/${make.replace(/ /g, '-').replace(/\//g, '-_-') }`;
        }

        if (model && model !== 'ALL') {
            route += `${model.replace(/ /g, '_').replace(/\//g, '-_-') }`;
        }

        if (yearFrom && yearFrom !== 'ANY') {
            route += `?yf=${yearFrom}`;
        }

        if (yearTo && yearTo !== 'ANY') {
            route += yearFrom && yearFrom !== 'ANY'
                ? `&yt=${yearTo}`
                : `?yt=${yearTo}`;
        }

        //clearModelField();
        makesField.querySelector('.js-suggestions__list').value = 'ALL';
        modelsField.querySelector('.js-suggestions__list').value = 'ALL';
        // modelsField.querySelector('.js-suggestions__input').disabled = true;

        window.location = route;

        // Clear submit button after 2 seconds
        setTimeout(() => {
            sbtBtn.disabled = false;
            sbtBtn.classList.remove('loading');
            spinner.remove();
        }, 2000);
    };

    document.querySelector('.h-adv-search__container .form-group__submit').addEventListener('click', submitAdvSearchHandler);

    // Hide suggestions list when clicking outside
    //document.addEventListener('click', function (e) {
    //    if (e.target !== autoCompleteInputs[0] && e.target !== autoCompleteInputs[1] && e.target !== dateRangeFields[0] && e.target !== dateRangeFields[1]) {
    //        // suggestionsList.forEach(el => el.style.display = 'none')

    //        if (autoCompleteInputs[0].value === '') {
    //            autoCompleteInputs[0].value = 'ALL';
    //            openClassRemove(autoCompleteInputs[0]);
    //        } else {
    //            openClassRemove(autoCompleteInputs[0]);
    //        }

    //        if (autoCompleteInputs[1].value === '') {
    //            autoCompleteInputs[1].value = 'ALL';
    //            openClassRemove(autoCompleteInputs[1]);
    //        } else {
    //            openClassRemove(autoCompleteInputs[1]);
    //        }

    //        if (dateRangeFields[0].innerText === '') {
    //            dateRangeFields[0].innerText = 'ANY';
    //            openClassRemove(dateRangeFields[0]);
    //        } else {
    //            openClassRemove(dateRangeFields[0]);
    //        }

    //        if (dateRangeFields[1].innerText === '') {
    //            dateRangeFields[1].innerText = 'ANY';
    //            openClassRemove(dateRangeFields[1]);
    //        } else {
    //            openClassRemove(dateRangeFields[1]);
    //        }

    //    }


    //});

    //const chevronIcons = document.querySelectorAll('.auto-complete-container__chevr');

    //const openOrCloseDropdown = (chevrIcon, type) => {
    //    const parent = chevrIcon.closest('.auto-complete-container');
    //    const isOpen = parent.classList.contains('open');

    //    closeDropDown(type); // Close other dropdowns

    //    if (isOpen) {
    //        openClassRemove(chevrIcon);
    //    } else {
    //        openClassAdd(chevrIcon);
    //    }
    //};

    //chevronIcons.forEach((chevrIcon) => {
    //    chevrIcon.addEventListener('click', (e) => {
    //        const inputField = chevrIcon.closest('.auto-complete-container').querySelector('.js-suggestions__input');
    //        const selectField = chevrIcon.closest('.auto-complete-container').querySelector('.js-suggestions__field');
    //        const listItems = chevrIcon.closest('.auto-complete-container').querySelectorAll('.js-suggestions__list option');
    //        const type = chevrIcon.closest('.auto-complete-container').classList.contains('js-adv-search__make')
    //            ? 'make'
    //            : chevrIcon.closest('.auto-complete-container').classList.contains('js-adv-search__model')
    //                ? 'model'
    //                : chevrIcon.closest('.auto-complete-container').classList.contains('js-adv-search__yfrom')
    //                    ? 'yfrom'
    //                    : chevrIcon.closest('.auto-complete-container').classList.contains('js-adv-search__yto')
    //                        ? 'yto'
    //                        : '';

    //        openOrCloseDropdown(chevrIcon, type);
    //        makeListItemsSelectable(listItems, type, inputField, selectField); // Enable selection for list items
    //        e.stopPropagation(); // Prevent the click event from propagating to the document click listener
    //    });
    //});

    //const makeListItemsSelectable = (listItems, type, inputField, selectField) => {

    //    listItems.forEach((item) => {
    //        item.addEventListener('click', () => {
    //            if (type === 'make' || type === 'model') {
    //                inputField.value = item.textContent.trim();
    //                openClassRemove(inputField);
    //            }
    //            if (type === 'yfrom' || type === 'yto') {
    //                selectField.innerText = item.textContent.trim();
    //                openClassRemove(selectField);
    //            }
    //        });
    //    });
    //};




});

//window.addEventListener('load', (e) => {
//    function selPrevMakeModel() {
//        if (lm1 != '') {
//            var ddlMake = document.getElementById('ddlNewMake');
//            ddlMake.value = String(lm1);
//            selMake(ddlMake);
//        }
//        if (lm2 != '') {
//            var ddlModel = document.getElementById('ddlNewModel');
//            ddlModel.value = String(lm2);
//            selModel(ddlModel);
//        }
//    }

//    selPrevMakeModel();

//});



