<template>
    <template v-if="isDesktop">
        <div v-show="isLiveBidding" class="rs-card_simple life-auction-block" ref="homeLiveBidding">
            <div class="rs-card_simple__header-container">
                <h3 class="rs-card_simple__header">{{title}}</h3>
                <div class="rs-card_simple__devider"></div>
            </div>
            <div class="life-auction-block__body rs-card_simple__container">
                <div class="js-pb">
                    <div class="pb-wrapper pb-wrapper-1"><div class="js-pb-1 progress-bar progress-bar--h"></div></div>
                    <div class="pb-wrapper pb-wrapper-2"><div class="js-pb-2 progress-bar progress-bar--h"></div></div>
                    <div class="pb-wrapper pb-wrapper-3"><div class="js-pb-3 progress-bar progress-bar--h"></div></div>
                </div>
                <table class="life-auction-table" border="0" cellpadding="8" cellspacing="0">
                    <tbody id="divABTable">
                    <tr :key="item.inventoryId" v-for="(item, idx) in arrByLot" :id="'abRow' + item.inventoryId">
                        <td class="life-auction-table-img">
                            <div>
                                <a :id="'abInvUrl' + item.inventoryPicturesId" :href="'.' + item.inventoryUrl" :aria-label="item.veh || 'No Image'">
                                    <img class='life-auction-table__img' v-if="item.imageUrl" :src="item.imageUrl" width="96" alt="" />
                                    <img class='life-auction-table__img' v-if="!item.imageUrl" src="/images/noimage.jpg" width="96" height="72" alt="" />
                                </a>
                            </div>
                        </td>
                        <td class="life-auction-table-vehicle">
                            <a :id="'abInvUrl' + item.inventoryId" :href="'.' + item.inventoryUrl">
                                <h2 class="small-header">
                                    <span v-if="item.veh" class="small-header">
                                        {{item.veh}}
                                    </span>
                                    <span v-if="!item.veh" class="small-header">
                                        {{ t(785, 'Loading...') }}
                                    </span>
                                </h2>

                            </a>
                            <div v-html="item.titleType"></div>
                            <div>{{item.mileageStr}}</div>
                        </td>
                        <td class="life-auction-table-bid">
                            <div v-if="item.cbid" class="life-auction-table-bid-price">{{item.cbid}}</div>
                            <div class="life-auction-table-location" v-html="item.b"></div>
                            <div :style="item.abStatusColor" class="life-auction-table-status">{{item.abStatus}}</div>
                            <div :class="`js-progress-bar progress-bar progress-bar--h js-progressbar_` + (idx + 1)" :data-idx="idx + 1"></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </template>
    <div v-else>
        <div class="home-best-deals">
            <div class="rs-card_simple__header-container" style="padding: 0 8px;">
                <h3 v-if="arrByLot.some(item => 'veh' in item)" style="text-align:left;margin-left:0;">{{title}}</h3>
                <div v-if="!arrByLot.some(item => 'veh' in item)" class="best-deals__skeleton-item" style="width: 100%;height:21.5px;margin-bottom:6px"></div>
                <div class="rs-card_simple__devider"></div>
            </div>
            <div class="home-best-deals__container" style="position:relative;">
                <div class="js-pb">
                    <div class="pb-wrapper pb-wrapper-1"><div class="js-pb-1 progress-bar progress-bar--h"></div></div>
                    <div class="pb-wrapper pb-wrapper-2"><div class="js-pb-2 progress-bar progress-bar--h"></div></div>
                    <div class="pb-wrapper pb-wrapper-3"><div class="js-pb-3 progress-bar progress-bar--h"></div></div>
                </div>
                <div class="home-best-deals__list">
                    <div :key="item.inventoryId" v-for="(item, idx) in arrByLot" class="home-best-deals__item">
                        <a :id="'abInvUrl' + item.inventoryId" :href="'.' + item.inventoryUrl" :aria-label="item.veh || 'No Image'">
                            <div class="home-best-deals__item-vehicle">
                                <h4 v-if="item.veh" class="text-truncate">{{ item.veh }}</h4>
                                <div v-else class="best-deals__skeleton-item" style="width: 180px;margin: 10px 0 2px;"></div>
                                <div class="home-best-deals__item-info">
                                    <span class="home-best-deals__item-icon">
                                        <img v-if="item.veh && item.imageUrl" :src="item.imageUrl" width="96" alt="" />
                                        <img v-if="item.veh && !item.imageUrl" src="/images/noimage.jpg" width="96" height="72" alt="" />
                                        <div v-if="!item.veh" class="best-deals__skeleton-item" style="width: 92px;height:58px;"></div>
                                    </span>
                                    <div class="home-best-deals__item-info-bid">
                                        <span v-if="item.veh" class="home-best-deals__key">{{t(752, 'Bid')}}</span>
                                        <div v-if="!item.veh" class="best-deals__skeleton-item" style="width: 18px;height:15px;"></div>
                                        <div class="home-best-deals__value">
                                            <span v-if="item.cbid">{{item.cbid}}</span>
                                            <div v-if="!item.veh" class="best-deals__skeleton-item" style="width: 80px;height:18px;"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="home-best-deals__item-from">
                                    <span v-if="item.veh" class="home-best-deals__value text-truncate" style="max-width:168px;" v-html="item.b"></span>
                                    <div v-if="!item.veh" class="best-deals__skeleton-item" style="width: 200px;height:14px;"></div>
                                </div>
                                <!-- <span v-html="item.titleType" class="text-truncate" style="height: 16px;"></span>
                                <span>{{ item.mileageStr }}</span> -->
                            </div>
                            <div class="home-best-deals__item--progressbar"></div>
                            <div>
                                <div :style="item.abStatusColor" class="life-auction-table-status" :class="{ sold: item.sold }">{{item.abStatus}}</div>
                                <div :class="`js-progress-bar progress-bar progress-bar--h js-progressbar_` + (idx + 1)" :data-idx="idx + 1"></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as signalR from '@microsoft/signalr';
    import axios from 'axios';
    import Format from "../utilities/formats";
    import { removeFromWatchlistNew, addToWatchlistNew, isMobileDevice } from '../utilities/controllers';
    import { animation } from '../mixins/animation';
    import { getValue, optimizeDestination } from '../utilities/helpers';
    import { useLocalizationStore } from '../stores/localization';

    import '../components/selectDropdown';

    const LANG = document.querySelector('#rsLang')?.value;
    let oldVal = null;

    export default {
        setup() {
            const localization = useLocalizationStore();
            return {
                t: localization.t,
            };
        },
        name: "Home",
        mixins: [animation],
        data() {
            return {
                isLiveBidding: false,
                broadcastHub: null,
                arrChnl: [],
                arrByLot: [{}],
                lotsList: [],
                liveBiddingData: [],
                filteredLiveBiddingData: [],
                reconnectFlag: false,
                channelGeneric: 'AB',
                progressName: '',
                title: '',
                isProgressAnimStart: 0,
                progressIdx: 1,
                oldVal: null,
                connection: null,
                isDesktop: false,
                isAuthenticated: false,

                testData: []
            }
        },
        created() {
            this.isAuthenticated = document.querySelector('#rsUsrAuth')?.value === 'true';
            if (this.isAuthenticated) {
                this.addToWatchlist();
                this.removeFromWatchList();
            }

            window.addEventListener('resize', () => {
                this.isProgressAnimStart = 0;
                this.progressbar1 = null;
                this.progressbar2 = null;
                this.progressbar3 = null;
                this.isDesktop = window.innerWidth > 768;
            });
        },
        watch: {
            arrByLot: {
                handler(newValue, oldValue) {
                    if (oldValue.length !== newValue.length) this.isProgressAnimStart = this.isProgressAnimStart++
                    if (oldValue.length !== newValue.length || newValue.length !== oldVal) {
                        this.isProgressAnimStart = this.isProgressAnimStart++
                    }

                    oldVal = newValue.length;
                },
                deep: true
            }
        },
        mounted() {
            // If desktop
            if (window.innerWidth > 768) {
                this.isDesktop = true;
            }

            // let broadcastHub;
            this.signalRConnect()

            this.$watch('isProgressAnimStart', (newLots, oldLots) => {

                this.$nextTick(() => {
                    if (this.isDesktop) {
                        const lbBlock = document.querySelector('#divABTable');
                        lbBlock?.querySelectorAll('tr').forEach(el => {
                            const _idx = el.querySelector('.js-progress-bar').dataset.idx;
                            const currClass = `.js-pb-${_idx}`;

                            this.progressBarAnimation(currClass, _idx);
                        })

                        const homeLBBlock = document.querySelector('#appLiveBidding');
                        const homeLBBlockRect = homeLBBlock.getBoundingClientRect();

                        if (this.progressbar1) {
                            document.querySelector('.pb-wrapper-1').style.cssText = `position: absolute; top: 152px; right: 10px`;
                            this.progressbar1.pause()
                        };
                        if (this.progressbar2) {
                            document.querySelector('.pb-wrapper-2').style.cssText = `position: absolute; right: 10px; top: 245px;`;
                            this.progressbar2.pause()
                        };
                        if (this.progressbar3) {
                            document.querySelector('.pb-wrapper-3').style.cssText = `position: absolute; right: 10px; top: 338px;`;
                            this.progressbar3.pause()
                        };
                    }

                    if (!this.isDesktop) {
                        const homeLBBlock = document.querySelector('.home-best-deals__list');
                        homeLBBlock?.querySelectorAll('.home-best-deals__item').forEach(el => {
                            const _idx = el.querySelector('.js-progress-bar').dataset.idx;
                            const currClass = `.js-pb-${_idx}`;
                            this.progressBarAnimation(currClass, _idx);
                        })

                        if (this.progressbar1) {
                            document.querySelector('.pb-wrapper-1').style.cssText = `position: absolute; top: 109px; left: 16px`
                            this.progressbar1.pause()
                        };
                        if (this.progressbar2) {
                            document.querySelector('.pb-wrapper-2').style.cssText = `position: absolute; top: 109px; left: 284px`
                            this.progressbar2.pause()
                        };
                        if (this.progressbar3) {
                            document.querySelector('.pb-wrapper-3').style.cssText = `position: absolute; top: 109px; left: 552px`
                            this.progressbar3.pause()
                        };
                    }
                });
            })

            // For mobile devices
            this.$watch('isDesktop', (newVal, oldVal) => {
                const liveBiddingContainer = document.querySelector('#appLiveBidding');
                if (newVal && isMobileDevice()) {
                    console.log('Desktop')
                    const targetBlock = document.querySelector('.top-savings');
                    targetBlock.before(liveBiddingContainer);
                    return;
                }

                if (!newVal && isMobileDevice()) {
                    console.log('Mobile')
                    document.querySelector('.home-page').before(liveBiddingContainer);
                    return;
                }
            })
        },
        methods: {
            // Create method for LB
            formatMoney(val, lang) {
                const _moneyF = new Format;
                return _moneyF.money(val, lang)
            },
            joinAB(channel) {
                this.broadcastHub.server.joinBCToGroup(channel);
            },
            searchArray(arr, obj) {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i] == obj) return true;
                }
                return false;
            },
            async signalRConnect() {
                this.connection = new signalR.HubConnectionBuilder()
                    .withUrl(signalRHubUrl)
                    .withAutomaticReconnect()
                    .configureLogging(signalR.LogLevel.Information)
                    .build();

                try {
                    await this.connection.start();
                    console.log("connected");
                } catch (err) {
                    console.log(err);
                    setTimeout(() => this.signalRConnect(), 5000);
                }

                try {
                    await this.connection.invoke("joinToGroup", "AB");
                } catch (err) {
                    console.error(err.toString());
                }

                this.connection.on("BidInfo", this.subscribeToAB);
            },
            subscribeToAB(data) {
                this.isLiveBidding = true;
                if (this.liveBiddingData.length < 10)
                    this.liveBiddingData = [data, ...this.liveBiddingData]

                if (data.lot === '' && !data.isover) return;

                const chnlLimit = 3;
                const ID = data.said.toString();

                // Manage channels
                if (!this.searchArray(this.arrChnl, data.said) && this.arrChnl.length < chnlLimit) {
                    this.arrChnl.push(data.said);
                } else if (this.arrChnl.length === chnlLimit && !this.reconnectFlag) {
                    this.connectSpecificLanes();
                }

                if (!this.title || this.title === '') {
                    this.title = data.re
                        ? this.t(22, 'Last Live Auction Replay')
                        : this.t(9772, 'Featured Live Auctions in Progress...');
                }
                if (this.$refs.homeLiveBidding)
                    this.$refs.homeLiveBidding.style.opacity = '1';

                const existingItem = this.arrByLot.find(e => e.said == data.said);

                if (existingItem) {
                    this.updateExistingItem(existingItem, data);
                }
                if (this.searchArray(this.arrChnl, data.said) && !this.lotsList.includes(data.lot)) {
                    this.searchByLot(data.ahid == 7 ? "IAA" : "COPART", data, ID);
                    this.lotsList.push(data.lot);
                    this.lotsList = [...new Set(this.lotsList)];
                }
            },
            updateExistingItem(item, data) {
                const updIdx = this.arrByLot.indexOf(item) + 1;
                this.progressIdx = updIdx;

                const pbWrapper = document.querySelector(`.pb-wrapper-${updIdx}`);
                if (pbWrapper?.classList.contains('hide')) {
                    pbWrapper.classList.remove('hide');
                }

                if (this[`progressbar${updIdx}`] && !data.sold && !data.apr) {
                    this[`progressbar${updIdx}`].restart();
                }

                if (item.lot != data.lot && this.arrByLot.length >= 3 && !this.lotsList.includes(data.lot)) {
                    this.searchByLot(data.ahid == 7 ? "IAA" : "COPART", data, data.said);
                }

                Object.assign(item, {
                    cbid: this.formatMoney(data.cbid, LANG),
                    sbid: data.sbid,
                    veh: data.veh,
                    lot: data.lot,
                    b: !data.bnt
                        ? (data?.b.toUpperCase() === 'STARTING BID' || data.b === '')
                            ? this.t(1326, 'STARTING BID')
                            : this.t(786, 'from') + ' ' + data.b.toUpperCase()
                        : item.b,
                    abStatus: this.getAbStatus(data),
                    abStatusColor: this.getAbStatusColor(data),
                    sold: data.sold,
                    apr: data.apr,
                    cd: data.cd,
                    bnt: data.bnt
                });

                if (item.abStatus && !data.bnt) {
                    pbWrapper?.classList.add('hide');
                }

                if (!this.isProgressAnimStart) this.isProgressAnimStart++;
            },
            getAbStatus(data) {
                if (data.sold && data.apr) return this.t(787, 'Sold - On Approval!');
                if (data.sold && !data.apr) return this.t(788, 'Sold!');
                if (data.cd) return this.t(789, 'Closing...');
                if (data.bnt) return this.t(8214, 'Bonus Time!');
                return '';
            },

            getAbStatusColor(data) {
                if (data.sold || data.cd) return 'color: red;';
                return 'color: green;';
            },
            connectSpecificLanes() {
                this.reconnectFlag = true;
                this.connection.invoke("LeaveFromGroup", "AB").then(function () {

                }).catch(err => console.error(err.toString()));

                var auctionIds = "";

                this.arrChnl.forEach(auctionId => {
                    auctionIds += auctionId + ";";
                });
                this.connection.invoke("MultipleLanes", auctionIds).then(function () {

                }).catch(err => console.error(err.toString()));

            },

            searchByLot(auctionHouse, dataParam, uniqueAuctionID) {
                const _format = new Format;

                let ar = this.arrByLot.find(e => e.said == uniqueAuctionID);
                if (ar) {
                    ar.imageUrl = '';
                }

                fetch(`/api/inventory/getbylot?auctionhouse=${auctionHouse}&uniqueauctionid=${uniqueAuctionID}&lot=${dataParam.lot}&nr=1`)
                    .then(response => response.json())
                    .then(data => {
                        if (data && !this.arrByLot.find(e => e.said == uniqueAuctionID)) {
                            data.said = uniqueAuctionID;
                            data.mileageStr = _format.number(data?.mileage, LANG) + ' - ' + this.mileageStatus(data?.mileageStatus);

                            let index = this.arrByLot.findIndex(item => Object.keys(item).length === 0);
                            if (index !== -1) {
                                this.arrByLot.splice(index, 1, { bid: dataParam.cbid, ...data });
                            } else {
                                this.arrByLot.push({ bid: dataParam.cbid, ...data });
                            }
                            const existingItem = this.arrByLot.find(e => e.said == dataParam.said);
                            this.updateExistingItem(existingItem, dataParam);
                        } else {
                            let ar = this.arrByLot.find(e => e.said == uniqueAuctionID);
                            if (ar) {
                                ar.mileageStr = _format.number(data?.mileage, LANG) + ' - ' + this.mileageStatus(data?.mileageStatus);
                                ar.imageUrl = data.imageUrl;
                                ar.inventoryUrl = data.inventoryUrl;
                                ar.titleType = data.titleType;
                            }
                        }
                    })
                    .catch(err => {
                        // console.log(err)
                    });
            },
            addToWatchlist() {
                // Add to watchlist handler
                const addToWLBtns = document.querySelectorAll('.js-add-to-watchlist');

                addToWLBtns.forEach(btn => {
                    btn.addEventListener('click', (e) => {
                        e.preventDefault();

                        const inventoryId = btn.dataset.inventoryid;

                        console.log('Add to watchlist clicked: ', inventoryId);

                        addToWatchlistNew(inventoryId, btn, 'en', false, true, '/', 0)
                    })
                })
            },
            removeFromWatchList() {
                const removeFromWLBtns = document.querySelectorAll('.js-remove-from-watchlist');
                if (removeFromWLBtns.length) {
                    removeFromWLBtns.forEach(btn => {
                        btn.addEventListener('click', (e) => {
                            e.preventDefault();

                            const inventoryId = btn.dataset.inventoryid;
                            removeFromWatchlistNew(inventoryId, btn, true)
                        })
                    })
                }
            }
        },
        computed: {
            mileageStatus() {
                return (status) => {
                    switch (status) {
                        case 'A':
                            return this.t(72, 'ACTUAL');
                        case 'N':
                            return this.t(74, 'NOT ACTUAL');
                        case 'X':
                            return this.t(1007, 'EXCEEDS MECHANICAL LIMITS');
                        case 'E':
                            return this.t(1341, 'EXEMPT');
                        default:
                            return '';
                    }
                }
            }
        }

    }

</script>