import { Toast } from "./toast";

export const searchInvHandler = () => {
    const searchInvBtn = document.querySelector('#lbtSearchByID');
    const inputInvId = document.querySelector('#txtInventoryId');
    
    if(searchInvBtn) {
        const _searchInv = async () => {
            inputInvId?.value;
            if(document.querySelector('.js-invalid-msg')) {
                document.querySelector('.js-invalid-msg')?.remove();
                inputInvId.style.removeProperty('border-color');
            }
            if(Number(inputInvId?.value) > 0) {
                searchInvBtn.classList.add( 'btn-disable_primary' );
                searchInvBtn.setAttribute('disabled', 'disabled');
                searchInvBtn.innerText = 'Processing...'
                try {
                    const response = await fetch(`/api/inventory/GetDetails?inventoryId=${inputInvId?.value}&nr=1`)
                    const data = await response.json()
                    data?.inventoryUrl
                        ? window.location.href = data.inventoryUrl
                        : new Toast({
                            message: `<h3 class="rs-mb-2">${document.querySelector('#lblAttention')?.value}</h3>${document.querySelector('#lblInvalidInventoryID')?.value}`,
                            type: 'danger',
                            confirm: true,
                            customButtons: [{text: 'OK'}]
                        });
                } catch( err ) {
                    // console.log(err)
                    new Toast({
                        message: `<h3 class="rs-mb-2">${document.querySelector('#lblAttention')?.value}</h3>${document.querySelector('#lblInvalidInventoryID')?.value}`,
                        type: 'danger',
                        confirm: true,
                        customButtons: [{text: 'OK'}]
                    });
                    setTimeout(() => {
                        searchInvBtn.innerText = document.querySelector('#lblSearch')?.value;
                        searchInvBtn.removeAttribute('disabled');
                        searchInvBtn.classList.remove( 'btn-disable_primary' );
                    }, 1000)
                }
            } else {
                const invalidMsg = document.createElement('span');
                // invalidMsg.classList.add('js-invalid-msg');
                invalidMsg.innerText = document.querySelector('#lblInvalidInventoryID')?.value;
                invalidMsg.style.color = 'red';
                const searchInventoryIdBlock =  document.querySelector('.js-search-inventoryId-block');
    
                searchInventoryIdBlock.style.position = 'relative';
                invalidMsg.style.position = 'absolute';
                invalidMsg.style.top = '35px';
                invalidMsg.style.left = '4px';
                invalidMsg.style.fontSize = '11px';
                inputInvId.style.borderColor = 'red';
                
                searchInventoryIdBlock.appendChild(invalidMsg);
                inputInvId.after(invalidMsg);
            }
        }
    
        searchInvBtn.addEventListener('click', async () => {
            await _searchInv();
        });
    
        inputInvId.addEventListener('keypress', async (e) => {
            if (e.charCode === 13) {
                await _searchInv();
            }
        });
    }
} 

