class Notification {
    constructor({title, msg, status}) {
      this.title = title;
      this.msg = msg;
      this.status = status;
    }

    open() {
        const nBlock = document.createElement('div');
        nBlock.classList.add('rs-notification');
        setTimeout(() => {
            nBlock.classList.add('active')
        }, 300);
    
        nBlock.innerHTML = `
            <div class="rs-notification__block">
                <span class="rs-notification__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="none"><path stroke="#F60" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M27.011 10.133 4.778 47.25a5.25 5.25 0 0 0 4.488 7.875h44.468a5.25 5.25 0 0 0 4.488-7.875L35.99 10.133a5.25 5.25 0 0 0-8.978 0v0Z"/><path stroke="#F60" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31.5 23.625v10.5M31.5 44.625h.024"/></svg>
                </span>
                <span>${this.msg}</span>
            </div>
        `;

        document.body.appendChild(nBlock);
    }

    close() {
        const nBlock = document.querySelector('.rs-notification');

        if(nBlock) {
            nBlock.classList.remove('active');
            setTimeout(() => {
                nBlock.remove();
            }, 500);
        }
    }
}

export default Notification;