import { createApp } from 'vue';
import calcHeaderModal from './vue/calcHeaderModal.vue';
import Header from './vue/header.vue'
import { toolTip } from './components/tooltip';
import { processingBtn } from './components/animation';
import { messeger } from './components/messegers';
import { checkInternetConnection, isMobileDevice } from './utilities/controllers';
import { getValue } from "./utilities/helpers";
import { accordionComponent } from './components/collapse.js';

import { createPinia } from 'pinia';
// Check Internet Connection
checkInternetConnection();

const bigSearch = document.querySelector('#aBigSearch');
const IS_WHITELABEL = document.querySelector('#isWl')?.value.toLocaleLowerCase() == 'true' ? true : false;

document.addEventListener("DOMContentLoaded", function () {
    let isMobDeviceState;

    const modalMaxHeight = () => {
        if (window.matchMedia("(max-width: 991px)").matches) {
            const doc = document.documentElement;
            const maxHeight = window.innerHeight - 96;
            doc.style.setProperty('--modal-max-height', `${maxHeight}px`);
        }
    }

    // Run resize on page load
    window.addEventListener('resize', modalMaxHeight);
    modalMaxHeight();

    const resizeHandler = () => {
        const asideMob = document.querySelector('.js-aside-search__mob');
        const asideDesk = document.querySelector('.js-aside-search__desk');

        if (window.innerWidth < 768) {
            // Updating position of advanced search block for mob
            if(!asideMob?.querySelector('.aside-search') && asideDesk) {
                const _asideSearch = asideDesk.querySelector('.aside-search');
                asideMob.appendChild(_asideSearch);
            }
            
            document.querySelectorAll('.aside-search').forEach(el => el.classList.add('mobile-filters', 'js-aside-mobile'));
            document.querySelectorAll('.navbar-collapse').forEach(el => el.classList.remove('desktop-collapse'));
            document.querySelectorAll('.aside-menu-title').forEach(el => el.classList.remove('hide'));

            // Setting the state of the device
            isMobDeviceState = true;
        } else {
            // Updating position of advanced search block for desktop
            if (!asideDesk) {
                return;
            }
            
            if (!asideDesk?.querySelector('.aside-search')) {
                const _asideSearch = asideMob.querySelector('.aside-search');
                asideDesk.appendChild(_asideSearch);
            }

            document.querySelectorAll('.aside-search').forEach(el => el.classList.remove('mobile-filters'));
            document.querySelectorAll('.aside-search').forEach(el => el.classList.remove('js-aside-mobile'));
            document.querySelectorAll('.navbar-collapse').forEach(el => el.classList.add('desktop-collapse'));
            document.querySelectorAll('.aside-menu-title').forEach(el => el.classList.add('hide'));

            // Setting the state of the device
            isMobDeviceState = false;
        }
    }
    
    window.addEventListener('resize', resizeHandler);
    
    // Init the resizeHandler function
    resizeHandler();

    // googleAuth(LANG)

    // Init accordion component
    accordionComponent();

    // ToolTip
    if (document.querySelector('.js-tooltip'))
        toolTip();

    // Processing Btn
    processingBtn();

    // Social links
    if (!IS_WHITELABEL)
        messeger();

    
    const search = (fts) => {
        // var fts = getValue('search');
        if (fts && fts.trim() !== '') {

            var cleantitle = 'salvage';
            var $cTitle = document.querySelector("#" + localPrefix + "hdnIsCleanTitle");
            if ($cTitle && $cTitle?.value === "1") {
                cleantitle = 'used';
            }

            var searchUrl = `./${cleantitle}-auto-auction-search?q=${fts.trim().toUpperCase()}&ua=1`;

            location.href = searchUrl; // '/' + _lang + '/search.aspx?q=' + fts.trim().toUpperCase();
        }
    }

    // Search - desktop
    document.querySelector('#aBigSearch')?.addEventListener('click', (e) => {
        e.preventDefault();
        const fts = getValue('search');
	    search(fts);
	    return false;
	});

    // Search input field - mob
    const searchInputMob = document.querySelector('.js-search-h__input');

    // Search handler method
    const searchHandlerMob = () => {
        const fts = searchInputMob?.value;
        search(fts);
    }

    // Search - mob
    document.querySelector('.js-search__submit')?.addEventListener('click', (e) => {
        e.preventDefault();

        searchHandlerMob();
        return false;
    });

    // Listen for enter key press
    const handleQuickSearchMob = (event) => {
        if (event.keyCode == 13) {
            event.returnValue = false;
            event.cancel = true;
            searchHandlerMob();

            return false;
        }
    }

    searchInputMob?.addEventListener('keypress', (e) => {
        handleQuickSearchMob(e);
    })

    const handleQuickSearch = (event) => {
        if (event.keyCode == 13) {
            event.returnValue = false;
            event.cancel = true;
            if (document.getElementById("aBigSearch") != null) {
                document.getElementById("aBigSearch").click();
            }
            return false;
        }
    }
    const searchInput = document.querySelector('.txtEnabledSearch');
    searchInput?.addEventListener('keypress', (e) => {
        handleQuickSearch(e);
    })

    //if (_rscn) {//Check if user is logged in
    //    showTopNotification({
    //        message: '\u26A0\uFE0F Effective January 1, 2024, the documentation fee has increased from $85 to $150 per transaction due to increased costs of title processing. This price will now include FedEx 2-day shipping for all titles shipped within the United States (excluding Hawaii, Alaska, and US territories).',
    //        id: 12292023,
    //        notificationType: 'warning',
    //        daysCookie: 365
    //    });
    //}
});

// Big search
const bigSearchHandler = () => {
    const _search = document.querySelector('#search')
    let fts = _search.value;

    if (fts && fts.trim() !== '') {
        var cleantitle = 'salvage';
        var searchUrl = `./${cleantitle}-auto-auction-search?q=${fts.trim().toUpperCase()}`;
        location.href = searchUrl;
    }
    return false;
};


if (bigSearch)
    bigSearch.addEventListener('click', function (e) {
        e.preventDefault();
        bigSearchHandler();
    });

const searchRadioType = document.querySelector('#hdnIsCleanTitle')?.value;

if (!document.querySelector('.js-aside-menu-home')) {
    const searchRadioBlock = document.querySelectorAll('.js-search-radio-1 input');
    if (searchRadioBlock?.length) {
        searchRadioBlock.forEach(el => {
            if (el.value === searchRadioType) {
                el.checked = true
            } else {
                el.checked = false
            }
        })
    }
}

const searchInvField = document.querySelector('.js-search-inventoryId');
if (searchInvField) {
    searchInvField.addEventListener('input', (e) => {
        searchInvField.value = e.target.value.replace(/[^0-9]/g, '')
    })
}

//MobDropdown
document.querySelector('.js-mobDropdown-btn')
    && document.querySelector('.js-mobDropdown-btn').addEventListener('click', e => {
        const btn = e.target;
        const parent = btn.parent(".js-mobDropdown");
        const menu = parent.find(".js-mobDropdown-menu");
        btn.classList.toggle("is-open");
        menu.slideToggle(300);
    });

const app = createApp(Header)
// app.component('bid-calculator', calcApp)
const pinia = createPinia();
app.use(pinia);
var calcApp = createApp(calcHeaderModal);
calcApp.use(pinia);


if (window.innerWidth < 768) {
    app.mount('#headerAppMob')
    calcApp.mount('#calcHeaderModalMob');
} else {
    app.mount('#headerApp')
    calcApp.mount('#calcHeaderModal');
}

// Footer ToTopButton
//ToTopButton
const toTopBtn = document.querySelector('.js-to-top');
// const toTopChatBtn = document.querySelector('.js-chat__totop');
// if (toTopBtn.length) {
// const scrollTrigger = 500;
const scrollTriggerBlock = document.querySelector('.js-totop-trigger');
const scrollTrigger = scrollTriggerBlock?.getBoundingClientRect()
const triggerValue = scrollTriggerBlock ? scrollTrigger.top : 500;

const backToTop = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > triggerValue) {
        toTopBtn.style.opacity = 1;
        toTopBtn.classList.add('show');
    } else {
        toTopBtn.style.opacity = 0;
        toTopBtn.classList.remove('show');
    }
};

backToTop();
window.addEventListener('scroll', function () {
    backToTop();
});
toTopBtn.addEventListener('click', function (e) {
    e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
});
// toTopChatBtn.addEventListener('click', function (e) {
//     e.preventDefault();
//     window.scroll({top: 0, left: 0, behavior: 'smooth'});
// });
// }

// Header Component & Search Mob
if (!document.querySelector('.wl-top-menu')) {
    const headerSearchBtn = document.querySelector('.js-nav-header-search');
    const searchContainer = document.querySelector('.js-search-container-mob');
    const searchContainerMain = document.querySelector('.js-search-block');
    const navbar = document.querySelector('.navbar-header-mob');
    const topMenu = document.querySelector('.top-menu') ? document.querySelector('.top-menu') : document.querySelector('.wl-top-menu');
    const navbarLogin = document.querySelector('.navbar-login');
    const advSearchBtn = document.querySelector('.js-btn-search-advanced');

    if (headerSearchBtn) {
        headerSearchBtn.addEventListener('click', () => {
            searchContainer?.classList.toggle('open');
            const _input = document.querySelector('.js-search-container-input');
            _input.focus();
        })
    }

    if (advSearchBtn) {
        advSearchBtn.addEventListener('click', () => {
            window.scroll({ top: searchContainerMain?.offsetTop - 53, left: 0, behavior: 'smooth' });
            document.querySelector('.aside-search').classList.add('open-advasnced');
        })
    }


    const observer1 = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                navbar.classList.add('fixed')
            } else {
                navbar.classList.remove('fixed')
            }
        })
    }, {});
    observer1.observe(topMenu);


    const observer2 = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {

            if (!entry.isIntersecting) {
                navbar.classList.add('search')
            } else {
                navbar.classList.toggle('search')
                searchContainer?.classList.remove('open');
            }
        })
    }, {});
    observer2.observe(searchContainerMain);
}

