// To use this modal run commands showModal('modal id') or hideModal('modal id');
// To use this modal with preventBodyScroll add options showModal('modal id', { preventBodyScroll: true });

window.addEventListener('load', (event) => {
    let closeButtons = document.querySelectorAll('.js-n-modal__close');
    let overlayBlocks = document.querySelectorAll('.js-n-modal__overlay');

    // Global method for showing modal
    window.showModal = (modalId, options) => {
        const { preventBodyScroll } = options || {};
        const modalElem = document.getElementById(modalId);
        if (modalElem) {
            modalElem.style.display = 'block';

            setTimeout(() => {
                modalElem.classList.add('show');
            }, 10);

            // Check close buttons 
            closeButtons = document.querySelectorAll('.js-n-modal__close');
            if (closeButtons.length > 0) {
                closeButtons.forEach((button) => {
                    button.addEventListener('click', () => {
                        hideModal(button.closest('.rs-modal').id);
                    });
                });
            
            }

            // Close modal when clicking on backdrop
            overlayBlocks = document.querySelectorAll('.js-n-modal__overlay');
            if (overlayBlocks.length > 0) {
                overlayBlocks.forEach((overlay) => {
                    overlay.addEventListener('click', (e) => {
                        if (e.target === overlay) {
                            hideModal(overlay.closest('.rs-modal').id);
                        }
                    });
                });
            }

            // Prevent body scroll. Only top position
            if (preventBodyScroll) {
                modalElem.querySelector('.js-n-modal__overlay').scrollTop = 0;
                document.body.style.overflow = 'hidden';
            }
        }
    }

    // Global method for hiding modal
    window.hideModal = (modalId, options) => {
        const modalElem = document.getElementById(modalId);
        if (modalElem) {
            modalElem.classList.remove('show');

            setTimeout(() => {
                modalElem.style.display = 'none';
            }, 300);
            document.body.style.overflow = '';
        }
    }

    // Close modal by clicking on close button
    closeButtons.forEach((button) => {
        button.addEventListener('click', () => {
            hideModal(button.closest('.rs-modal').id);
        });
    });

    // Close modal when clicking on backdrop
    overlayBlocks.forEach((overlay) => {
        overlay.addEventListener('click', (e) => {
            if (e.target === overlay) {
                hideModal(overlay.closest('.rs-modal').id);
            }
        });
    });
});
