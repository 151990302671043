// Collapse like in BS
export const collapseComponent = () => {
    const collapseDetails = document.getElementById('collapse-component');
    const collapseContent = collapseDetails.querySelector('.js-collapse-content');

    collapseDetails.addEventListener('toggle', (e) => {
        if (collapseDetails.open) {
            collapseContent.style.maxHeight = collapseContent.scrollHeight + 'px';
        } else {
            collapseContent.style.maxHeight = null;
        }
    });
}

export const accordionComponent = () => {
    const accordions = document.querySelectorAll('.js-accordion-component');

    accordions.forEach((accordion) => {
        const accordionItems = accordion.querySelectorAll('.js-accordion-item');
        const accordionContents = accordion.querySelectorAll('.js-accordion-content');
        accordionItems.forEach((item, index) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                const currentContentEl = accordionContents[index];
                item.classList.toggle('open');

                if (item.classList.contains('open')) {
                    currentContentEl.style.maxHeight = currentContentEl.scrollHeight + 'px';
                } else {
                    currentContentEl.style.maxHeight = 0;
                }
            });
        });
    });

}

export const collapse = () => {
    const collapse = document.querySelector('.js-collapse');
    const collapseBtn = document.querySelector('.js-collapse-btn');
    const collapseBlock = document.querySelector('.js-collapse-block');

    collapseBtn.addEventListener('click', () => {
        collapseBlock.classList.toggle('open')
    })
}

// after the page was loaded and touchmoved down on IOS, the 'View All Makes' button is working from second click
// to fix this issue, we need to add an empty event listener on touchmove
window.addEventListener('touchmove', () => {});

export const viewAllHiding = (hidingBlock, {
    openText = 'View All',
    hideText = 'Hide All'
}) => {
    // const vehiclesBlock = document.querySelector('.js-home-vehicles');

    const viewAllBlock = document.createElement('div');
    //const hidingBlockHeight = hidingBlock.getBoundingClientRect().height;

    //hidingBlock.style.height = document.querySelector('html[data-theme="theme-6"]') || document.querySelector('html[data-theme="theme-7"]') ? '341px' : `364px`;

    hidingBlock.appendChild(viewAllBlock);
    viewAllBlock.classList.add('home-viewall');

    const viewAllBtn = document.createElement('div');
    const makeTabs = document.querySelectorAll('.js-makelist-tab');

    viewAllBtn.innerHTML = `<span>${openText}</span>
                            <span>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.25 13.5L6.75 9L11.25 4.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>                        
                            </span>`;
    viewAllBlock.appendChild(viewAllBtn);

    viewAllBtn.classList.add('home-viewall__btn');
    viewAllBtn.classList.add('rs-btn');
    viewAllBtn.classList.add('rs-btn_sm');
    viewAllBtn.classList.add('rs-btn_default');
    viewAllBtn.classList.add('rs-btn_with-icon--right');

    const changeViewEvent = (type) => {
        const tab = Number(type);
        if (tab === 1) {
            hidingBlock.classList.remove('open')
            //hidingBlock.style.height = `341px`;
            document.querySelector('.js-makelist-tab[data-tab="2"]').classList.remove('active');
            document.querySelector('.js-makelist-tab[data-tab="1"]').classList.add('active');
            document.querySelectorAll('.home-makeslist__item').forEach(item => item.classList.contains('recomended') ? item.classList.remove('not-recomended') : item.classList.add('not-recomended'));
            // viewAllBtn.innerHTML = `<span>${openText}</span>
            // <span>
            //     <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            //         <path d="M11.25 13.5L6.75 9L11.25 4.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            //     </svg>                        
            // </span>`;
        } else {
            // hidingBlock.classList.add('viewall-animate-open')
            // setTimeout(() => {
            hidingBlock.classList.add('open');
            //hidingBlock.style.height = `${hidingBlockHeight}px`;
            document.querySelector('.js-makelist-tab[data-tab="1"]').classList.remove('active');
            document.querySelector('.js-makelist-tab[data-tab="2"]').classList.add('active');
            document.querySelectorAll('.home-makeslist__item').forEach((item) => {
                item.classList.remove('not-recomended');
            })
            // viewAllBtn.innerHTML = `<span>${hideText}</span>
            // <span>
            //     <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            //         <path d="M11.25 13.5L6.75 9L11.25 4.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            //     </svg>                        
            // </span>`;
            // hidingBlock.classList.remove('viewall-animate-open')
            // }, 300)
        }
    }

    if (document.querySelector('html[data-theme="theme-6"]') || document.querySelector('html[data-theme="theme-7"]')) {
        makeTabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                const tabType = tab.getAttribute('data-tab');
                changeViewEvent(tabType);
            })
        });
    } else {
        viewAllBtn.addEventListener('click', () => {
            if (hidingBlock.classList.contains('open')) {
                hidingBlock.classList.remove('open')
                hidingBlock.style.height = `364px`;
                viewAllBtn.innerHTML = `<span>${openText}</span>
                <span>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 13.5L6.75 9L11.25 4.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                        
                </span>`;
            } else {
                hidingBlock.classList.add('viewall-animate-open')
                // setTimeout(() => {
                hidingBlock.classList.add('open');
                hidingBlock.style.height = `${hidingBlockHeight + 30}px`;
                viewAllBtn.innerHTML = `<span>${hideText}</span>
                    <span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.25 13.5L6.75 9L11.25 4.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>                        
                    </span>`;
                hidingBlock.classList.remove('viewall-animate-open')
                // }, 300)
            }
        })
    }
}