export default {
    formatCurrency: (num) => {
        num = num.toString().replace(/\$|\,/g, '');
        if (isNaN(num)) num = '0';
        let sign = (num == (num = Math.abs(num)));
        num = Math.floor(num * 100 + 0.50000000001);
        let cents = num % 100;
        num = Math.floor(num / 100).toString();
        if (cents < 10) cents = '0' + cents;
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
            num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
        // return (((sign) ? '' : '-') + '$' + num + '.' + cents);
        return (((sign) ? '' : '-') + '$' + num);
    },
    getArrayIndex: (arr, obj) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === obj) return i;
        }
        return -1;
    },
    getLabel: (lblId) => {
        return document.getElementById(lblId) === null ? '' : document.getElementById(lblId).innerHTML;
    },
    getValue: (lblName) => {
        return document.getElementById(lblName) === null ? '' : document.getElementById(lblName).value;
    },
    setLabel: (lblName, lblValue) => {
        if (document.getElementById(lblName) === null ? '' : document.getElementById(lblName).innerHTML !== lblValue) {
            var el = document.getElementById(lblName);
            if (el)
                el.innerHTML = lblValue;
        }
    },
    setValue: (lblName, lblValue) => {
        if (getValue(lblName) !== lblValue) {
            document.getElementById(lblName).value = lblValue;
        }
    },
    abNavigate(row) {
        var vid = getLabel('abIID' + row);
        var invUrl = getLabel('abInvUrl' + row);
    
        if (vid != '' && invUrl != '') {
            window.open(invUrl, '_blank');
            //window.location.href = '/inventory/' + vtitle + '/' + vid + '/';
            //window.location.href = '/Inventory.aspx?InventoryID=' + vid + '&vehTitle=' + vtitle;
        }
    },
    searchArray(arr, obj) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] == obj) return true;
        }
        return false;
    },
    formatNumber(nStr) {
        nStr += '';
        var x = nStr.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    }
};