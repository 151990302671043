export const toolTip = () => {
    const _ttItem = document.querySelectorAll('.js-tooltip');

    const createTT = (text, top, left, position) => {
        const _ttBlock = document.createElement("div");
        _ttBlock.classList.add('jsTTBlock')
        _ttBlock.innerHTML = text;  
        _ttBlock.classList.add('active')
        document.body.appendChild(_ttBlock)
        _ttBlock.style.position = 'absolute'
        _ttBlock.style.top = `${top + 30}px`
        position === 1
            ? _ttBlock.style.left = `${left - 200 }px`
            : _ttBlock.style.left = `${left - 420 }px`
        
    }
    const removeTT = (tt) => {
        document.body.removeChild(tt)
    }

    _ttItem.forEach( (el) => {
        let currentElem = null;
        el.onmouseover = (e) => {
            if (currentElem) return;
            const target =  e.target.parentNode
            currentElem = target;
            let rect = el.getBoundingClientRect();
            const text = el.getAttribute('data-text');
            const position = el.getAttribute('data-position');
            position !== 'right'
                ? createTT(text, e.pageY - 10, rect.x, 1)
                : createTT(text, e.pageY - 10, rect.x, 2)
            
        }
        el.onmouseout = (e) => {
            if (!currentElem) return;
            let relatedTarget = e.relatedTarget;
            while (relatedTarget) {
                if (relatedTarget == currentElem) return;
            
                relatedTarget = relatedTarget.parentNode;
              }
              currentElem = null;
            const _ttBlock = document.querySelector('.jsTTBlock');
            _ttBlock
                && removeTT(_ttBlock)
        }
    })

    
}