export const validateEmail = (email) => {
    if (!email) {
        return false;
    }
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    return reg.test(email);
};

export const validatePhoneNumber = (input) => {
    // const regex = /^\d{3}-\d{3}-\d{4}$/;
    const regex = /^[0-9()+-\s]+$/;

    if (input.validity.valid && regex.test(input?.value)) {
      return true;
    } else {
      return false;
    }
};

export const validateOneMorePass = (firstPass, secPass) => {
    if (firstPass === secPass) {
        return true;
    }

    return false;
};

// Method to scroll to the anchor with margin at the top
export const moveToAnchor = (marginTop = 80) => {
    // Method    
    const scrollToAnchorWithMargin = (anchorId, margin) => {
        const element = document.getElementById(anchorId);
        if (element) {
            const rect = element.getBoundingClientRect();
            const offset = window.pageYOffset || document.documentElement.scrollTop;
        
            const scrollPosition = rect.top + offset - margin;
        
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth',
            });
        }
    }
    
    // Run method
    setTimeout(() => {
        document.querySelectorAll('.js-link-anchor').forEach((anchor) => {

            anchor.addEventListener('click', (e) => {
                e.preventDefault();
                const anchorId = anchor.dataset.id.slice(1);
                scrollToAnchorWithMargin(anchorId, marginTop);
            });
        });
    }, 1000)
}

// Method to check string if not empty -> add comma before it
export const addCommaBeforeString = (str) => {
    if (str && str.trim() !== '') {
      return `, ${str.trim()}`;
    }
    return str;
}

// Method to check string if not empty -> add whitespace before it
export const addWhitespaceBeforeString = (str) => {
    if (str && str.trim() !== '') {
      return ` ${str.trim()}`;
    }
    return str;
}

// Get value by id (legacy)
export const getValue = (lblName) => {
	return document.getElementById(lblName) == null ? '' : document.getElementById(lblName).value;
}

// Get label (innerHTML) by id (legacy)
export const getLabel = (lblName) => {
	return document.getElementById(lblName) == null ? '' : document.getElementById(lblName).innerHTML;
}

// Check if input is checked by id (legacy)
export const getChecked = (lblName) => {
    return document.getElementById(lblName) == null ? false : document.getElementById(lblName).checked;
}

// Set  input value by id (legacy)
export const setValue = (lblName, lblValue) => {
	if (getValue(lblName) != lblValue) {
		document.getElementById(lblName).value = lblValue;
	}
}

//  Set label (innerHTML) by id (legacy)
export const setLabel = (lblName, lblValue) => {
    if (getLabel(lblName) != lblValue) {
        var el = document.getElementById(lblName);
        if(el)
            el.innerHTML = lblValue;
	}
} 

// Set  input to checked by id (legacy)
export const setChecked = (lblName, lblValue) => {
    if (getChecked(lblName) != lblValue) {
        document.getElementById(lblName).checked = lblValue;
    }
}

export const getArrayIndex = (arr, obj) => {
	for (var i = 0; i < arr.length; i++) {
		if (arr[i] == obj) return i;
	}
	return -1;
}
export const parseInvTitle = (title) => {
    title = title.trim();
    title = encodeURIComponent(title);
    //title = title.replace(/\//g, ' ');
    //title = title.replace(/ /g, '+');
    console.log(title);
   // title = title.replace(/\./g, '-');
    //title = title.replace(/;/g, '-');
    //title = title.replace(/,/g, '-');
    //title = title.replace(/\+/g, '-');
    title = title.replace(/%20/g, '+');
    return title.toLowerCase();
}

export const decodeMake = (make) => {
    if (make == null)
        return null;
    make = make.trim();
    make = make.toLowerCase();
    make = decodeURIComponent(make);
    //make = make.replace(/\.-/g, '. ');
   
    //if (make.indexOf('harley') != -1 && make.indexOf('mercedes') != -1
    //    && make.indexOf('can-am') != -1 && make.indexOf('e-ton america') != -1 &&
    //    make.indexOf('k-z') != -1 && make.indexOf('r-vision') != -1) {
    //    make = make.replace(/-/g, ' ');
    //}

    return make;
}

export const encodeInvModel = (model) => {
    if (model) {
        model = model.trim();
        model = encodeURIComponent(model);
        //model = model.replace(/\//g, '-_-');
        //model = model.replace(/ /g, '+');
        model = model.replace(/%20/g, '+');
        //model = model.replace(/&/g, '%26');
        return model.toLowerCase();
    }

    return "";
}

export const decodeInvModel = (model) => {
    if (model) {
        model = model.trim();
        //model = model.replace(/-_-/g, '/');
        model = model.replace(/\+/g, ' ');
        //model = model.replace(/%26/g, '&');
        model = decodeURIComponent(model);
    }

    return model;
}

export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const fetchUrl = () => {
    fetch(proxyUrl)
        .then(response => {
            if (!response.ok) {
                throw new Error('There is no network response ' + response.statusText);
            }
            return response.json();
        })
        .then(data => {
            if (data && data.isSuccess === true) {
                
            }
        })
        .catch(error => {
            console.error('There is some problems:', error);
        });
}

export const isDevice = () => {
    if(/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }
    return false;
}

const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}

//  Method to remove 'from' from string
export const optimizeDestination = (str) => {
    return typeof str === 'string' && str.trim() !== '' ? str?.replace(/from /i, '') : str;
}