<template>
    <div>
        <button type="button" class="btn increase-limit js-increase-limit" @click="increaseLimitHandler()">
            <span>INCREASE LIMIT</span>
        </button>
        <div class="rs-modal n-modal rs-calculate" style="position: absolute; text-align: left" id="calcModal" aria-hidden="true">
            <div class="modal__overlay js-n-modal__overlay" tabindex="-1">
                <div class="modal__container" style="position: relative;" role="dialog" aria-modal="true" aria-labelledby="calcModal-title">
                    <header class="modal__header">
                        <div></div>
                        <button class="modal__close js-n-modal__close" aria-label="Close modal"></button>
                    </header>
                    <div class="modal__body">
                        <keep-alive>
                            <calculator :is-top="0"></calculator>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { rsTranslator } from '../mixins/translate';
    import calculator from './calculator.vue'

    export default {
        name: "BidCalculator",
        mixins: [rsTranslator],
        components: { calculator },
    data() {
        return {
            isShow: false,
            isCalcHide: true,
            isHeaderCalculator: false,
            isShowDepositInfo: false,
            biddingLimitHight: 83,
            calcBlockHight: null,
            inventoryId: null,
            currLang: 'en',
            rCapC: null,
            officeEmail: '',
            newProposeValue: '',
            wasChanged: false,
            desiredLimit: null,
            requiredDeposit: {},
            ccPaymentUrl: "",
        }
    },
    methods: {
        increaseLimitHandler() {
            console.log(calculator);
            showModal('calcModal');
        }
    }
}

</script>