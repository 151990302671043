import gsap from "gsap";
export const animation = {
    data() {
        return {
            //progressItems: [],
            progressbar1: null,
            progressbar2: null,
            progressbar3: null,
            progressbar: null
        };
    },
    methods: {
        progressBarItemAnimation() {

        },
        progressBarListAnimation(elList) {
            if (!elList?.length) return;

            elList.forEach((element, idx) => {
                this.progressBarAnimation(`.${element}`, idx + 1)

            });
        },
        progressBarAnimation(parentClass, idx) {
            // Reset the width of the progress bar
            gsap.set(parentClass, { width: "1%" });

            this[`progressbar${idx}`] = gsap.to(parentClass, {
                duration: 5,
                width: "100%",
                // ease: "power2.outIn",
                ease: "none",
                paused: true,
                onStart: function () {
                    const target = this.targets()[0];
                    // console.log("🚀 ~ file: animation.js:40 ~ progressBarAnimation ~ target:", target)
                    target.style.transition = "none";
                    target.style.width = "1%";
                    target.style.backgroundColor = "#009707";
                },
                onUpdate: function () {
                    const target = this.targets()[0];
                    var progress = gsap.getProperty(target, "width");
                    if (progress >= 60) {
                        target.style.backgroundColor = "#f60";
                    }
                    if (progress >= 80) {
                        target.style.backgroundColor = "#d00";
                    }
                },
                onComplete: function () {

                }
            });
        }

    }
};