import { useLocalizationStore } from '../stores/localization';

export const rsTranslator = {
    methods: {
        async getTranslateData(lang) {
            // Use the Pinia store to fetch translations
            await useLocalizationStore().fetchTranslations(lang);
        },

        t(id, text = '') {
            // Use the Pinia store for translation
            return useLocalizationStore().t(id, text);
        },
    },
};