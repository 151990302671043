import { getValue } from "../utilities/helpers";

export default class CountdownSearch {
    constructor(obj, tDate, cDate) {
        this.obj		= obj;
        this.TargetDate = tDate;
        this.CurrentDate = cDate;
        this.CountActive = true;
    }

    calcage = (secs, num1, num2) => {
      const s = ((Math.floor(secs/num1))%num2).toString();
      // if (s.length < 2) s = "0" + s;
      return (s);
    }

    countBack = (secs) => {
    
    //    this.DisplayStr = this.DisplayFormat.replace(/%%D%%/g, this.Calcage(secs, 86400, 100000) < 0 ? 0 : this.Calcage(secs, 86400, 100000));
    //    this.DisplayStr = this.DisplayStr.replace(/%%H%%/g, this.Calcage(secs, 3600, 24) < 0 ? 0 : this.Calcage(secs, 3600, 24));
    //    this.DisplayStr = this.DisplayStr.replace(/%%M%%/g, this.Calcage(secs, 60, 60) < 0 ? 0 : this.Calcage(secs, 60, 60));
    //    this.DisplayStr = this.DisplayStr.replace(/%%S%%/g, this.Calcage(secs, 1, 60) < 0 ? 0 : this.Calcage(secs, 1, 60));
    //    
        const DD = this.calcage(secs, 86400, 100000) < 0 ? 0 : this.calcage(secs, 86400, 100000);
        const HH = this.calcage(secs, 3600, 24) < 0 ? 0 : this.calcage(secs, 3600, 24);
        const MM = this.calcage(secs, 60, 60) < 0 ? 0 : this.calcage(secs, 60, 60);
        const SS = this.calcage(secs, 1, 60) < 0 ? 0 : this.calcage(secs, 1, 60);
    
    
        // setLabel('testDiv', getValue('HdnMinutes'));
        // document.getElementById('testDiv').innerHTML = 'THIS IS IT';
    
        if (HH == 0 && DD == 0) { // Minutes & Seconds
            this.obj.innerText = MM == 0 ? '' : MM + ' ' + getValue('HdnMinute' + (MM == 1 ? '' : 's'));
            this.obj.innerText += ' ' + SS + ' ' + getValue('HdnSecond' + (SS == 1 ? '' : 's'));
        }

        if (HH > 0 && DD == 0) { // Hours & Minutes
            this.obj.innerText = HH + ' ' + getValue('HdnHour' + (HH == 1 ? '' : 's'));
            this.obj.innerText += MM == 0 ? '' : ' ' + MM + ' ' + getValue('HdnMinute' + (MM == 1 ? '' : 's'));
        }

        if (DD > 0) { // Days and Hours
            this.obj.innerText = DD + ' ' + getValue('HdnDay' + (DD == 1 ? '' : 's'));
            this.obj.innerText += HH == 0 ? '' : ' ' + HH + ' ' + getValue('HdnHour' + (HH == 1 ? '' : 's'));
        }
    
        if (DD == 0 && HH == 0 && MM == 0 && SS == 0) {
            this.CountActive = false;
            this.obj.innerText = getValue('trInProg');
        }   
        
    
      // document.getElementById(this.Div).innerHTML = this.DisplayStr;
        if (this.CountActive) {
            var self = this;
            setTimeout(function () { self.countBack(secs - 1); }, 990);
        }
    }
    
    init = () => {
        const dthen = new Date(this.TargetDate);
        const dnow = new Date(this.CurrentDate);
        const ddiff = new Date(dthen - dnow);
        let gsecs = Math.floor(ddiff.valueOf() / 1000);
        this.countBack(gsecs);
    }
}