// import '../sass/searchpage.scss';
import { createApp } from 'vue';
import Home from "./vue/home.vue";
import { searchInvHandler } from "./components/searchBar";
import { viewAllHiding } from "./components/collapse";
import { createPinia } from 'pinia';
const app = createApp(Home);
const pinia = createPinia();
app.use(pinia);
app.mount('#appLiveBidding');
// console.log('Creating Home app')


// Init Search inventory
searchInvHandler();

const vehiclesBlock = document.querySelector('.js-home-vehicles');
viewAllHiding(vehiclesBlock, {
    openText: 'View All Makes',
    hideText: 'Hide All Makes'
});

// Default LiveBidding
