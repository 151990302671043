import { getCookie, setCookie } from "../utilities/helpers";
// TopNotification plugin
// window.addEventListener('load', (event) => {
const layoutBlock = document.querySelector('#rsLayoutWrappeer');

// Global method for showing modal
window.showTopNotification = ({ message, id = 1, notificationType = 'danger', nameCookie = 'notificationClosed', daysCookie = 0 }) => {
    if (getCookie(`notificationClosed_${id}`) === 'true') {
        return;
    }
    const topNotify = document.createElement('div');
    layoutBlock.insertBefore(topNotify, layoutBlock.childNodes[0]);
    topNotify.classList.add('rs-notification-top');
    topNotify.style.display = "block";
    topNotify.classList.add(`rs-notification-top--${notificationType}`);

    // Create notification element
    const notification = document.createElement("div");
    notification.className = "notification";
    notification.setAttribute('data-notify-id', id);

    // Add notification content
    notification.innerHTML = `
                <div class="rs-notification-top__block">
                    <div class="rs-notification-top__content js-invoice-warning-addr">       
                        ${message}
                    </div>
                </div>
                <div class="rs-notification-top__close js-rs-notification-top__close">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M4.5 4.5L13.5 13.5" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>            
                </div>
        `;

    topNotify.appendChild(notification);

    const closeNotification = () => {
        // Find the element based on the id and pass it to the close function
        const el = document.querySelector(`[data-notify-id="${id}"]`);
        close(el);

        if (daysCookie) setCookie(`${nameCookie}_${id}`, true, daysCookie)
    };

    notification.querySelector('.js-rs-notification-top__close').addEventListener('click', closeNotification);
    //notification.querySelector('.js-rs-notification-top__close').addEventListener('click', (e) => close(e.target))
}

// Global method for hiding notification
window.hideTopNotification = (id) => {
    const el = document.querySelector(`[data-notify-id="${id}"]`);

    // Close
    close(el);
}

// Close notification method
const close = (el) => {
    const parentEl = el.closest('.notification');

    // If notification exists, remove it
    if (parentEl) {
        parentEl.remove();
    }

    // Select all elements with the class '.rs-notification-top'
    const notificationBlocks = document.querySelectorAll('.rs-notification-top');

    // Check each notification block
    notificationBlocks.forEach((notificationBlock) => {
        // If there are no notifications left in this block, remove the block
        if (notificationBlock.children.length === 0) {
            notificationBlock.style.display = "none";
        }
    });
}
// });