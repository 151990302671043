import { defineStore } from 'pinia';

export const useLocalizationStore = defineStore('localization', {
  state: () => ({
    lang: 'en',
    translate: {},
  }),

  actions: {
    async fetchTranslations(lang) {
      if (!this.translate[lang]) {
        const convertArrayToObject = (array, key) => {
          const initialValue = {};
          return array.reduce((obj, item) => {
            return {
              ...obj,
              [item[key]]: item,
            };
          }, initialValue);
        };

        const response = await fetch(`/api/translations/gettranslations?langCode=${lang}&nr=1`);
        const data = await response.json();

        this.translate = { ...this.translate, [lang]: convertArrayToObject(data, 'id') };
      }

      this.lang = lang;
    },

    t(id, text = '') {
      return this.translate[this.lang]
        ? this.translate[this.lang][id]
          ? this.translate[this.lang][id].t
          : text
        : text;
    },
  },
});