const dropdownTogglesInit = () => {
    const dropdownToggles = document.querySelectorAll('[data-toggle="dropdown"]');
    
    dropdownToggles.forEach((toggle) => {
        toggle.addEventListener('click', (e) => {
            // e.preventDefault();
            
            const menu = toggle.nextElementSibling;
            const isOpen = toggle.getAttribute('aria-expanded') === 'true' ? true : false;

            if (!isOpen) {
                menu.style.display = 'block';
                toggle.setAttribute('aria-expanded', 'true');
            } else {
                menu.style.display = 'none';
                toggle.setAttribute('aria-expanded', 'false');
            }
        });
    
        // Dropdowns on hover
        toggle.closest('.dropdown').addEventListener('mouseover', (e) => {
            e.preventDefault();
            const menu = toggle.nextElementSibling;
            menu.style.display = 'block';
        });
    
        toggle.closest('.dropdown').addEventListener('mouseout', (e) => {
            e.preventDefault();
            const menu = toggle.nextElementSibling;
            menu.style.display = 'none';
            toggle.setAttribute('aria-expanded', 'false');
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    // Dropdowns
    dropdownTogglesInit();
});

export { dropdownTogglesInit };