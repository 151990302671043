export const blurBlock = (el, status) => {
    // Status: 0 - add; 1 - remove
    if(status === 0) {
        el.classList.add('blur');
        return;
    }

    el.classList.add('blur-removing');
    setTimeout(() => {
        el.classList.remove('blur');
        el.classList.remove('blur-removing');
    }, 500);
}

export const processingBtn = (text = 'Processing...') => {
    const _btns = document.querySelectorAll('.js-btn-processing');
    if(!_btns.length) 
        return

    _btns.forEach( btn => {
        // console.log("🚀 ~ file: animation.js ~ line 22 ~ processingBtn ~ btn.tagName ", btn.tagName )
        const processingBtnTag = btn.tagName.toLowerCase();
        if (processingBtnTag == 'input') {
            btn.addEventListener('click', (e) => {
                // btn.setAttribute("disabled", "disabled");
                if(!e.target?.form?.noValidate) 
                    return;

                btn.value = text;
                btn.classList.add('btn-disable_primary');
                if(e.target.attributes[0].nodeValue != 'submit' && e.target.type != 'submit' )
                    btn.setAttribute("disabled", "disabled");
            })
        } else if(processingBtnTag == 'a') {
            btn.addEventListener('click', (e) => {
                // e.preventDefault();
                e.target.style.color
                btn.innerText = text;
                btn.classList.add('link-disable_primary');
            })
        } else {
            btn.addEventListener('click', (e) => {
                // e.preventDefault();
                // console.log("🚀 ~ file: animation.js ~ line 45 ~ btn.addEventListener ~ e", e)

                if(!e.target?.form?.noValidate) 
                    return
                
                btn.innerText = text;
                btn.classList.add('btn-disable_primary');
                if(e.target.attributes[0].nodeValue != 'submit' && e.target.type != 'submit' )
                    btn.setAttribute("disabled", "disabled");
            })
        }
    } )
};

export const processingBtnEvent = (text = 'Processing...') => {
    console.log('Processing Event')
    // btn.setAttribute("disabled", "disabled");
    if(!e.target?.form?.noValidate) 
        return;

    btn.value = text;
    btn.classList.add('btn-disable_primary');
    if(e.target.attributes[0].nodeValue != 'submit' && e.target.type != 'submit' )
        btn.setAttribute("disabled", "disabled");
}

export const resizeBtns = (el) => {
    console.log("🚀 ~ file: animation.js ~ line 54 ~ resizeBtns ~ el", el);
    // const elArray = Array.prototype.slice.call(el);
    // console.log("🚀 ~ file: animation.js ~ line 56 ~ resizeBtns ~ elArray", elArray)
    // const isActive = elArray.find( item => item.classList.contains('active') )
    // console.log("🚀 ~ file: animation.js ~ line 56 ~ resizeBtns ~ isActive", isActive)
    if(el.length === 2) {
        
            const _hasActiveClass = () => {
                let activeClass = []
                
                el.forEach( item => {
                    if(item.classList.contains('active')) {
                        activeClass = [...activeClass, true]
                    } else {
                        activeClass = [...activeClass, false]
                    }
                } )
        
                return activeClass.includes(true)
            }
        
            // _hasActiveClass()
            
            el.forEach( item => {
                console.log("🚀 ~ file: animation.js ~ line 57 ~ resizeBtns ~ item", item)
        
                item.addEventListener('click', () => {
                    if( _hasActiveClass() )
                    item.classList.contains('active') ? item.querySelector('.inventory-header-btn').style.width = '120%' : item.querySelector('.inventory-header-btn').style.width = '80%'
                })
            } )
        }

    }

export const bidButtonPriceAnimation = () => {
    var counter = 0;
    var button = document.querySelector('.bid-btn');
    // var counterContainer = document.querySelector('.js-bid-chevron');
    const bidBtnWrapper = document.querySelector('.bid-animation__wrapper');
    
    button.addEventListener('click', function() {
      const newChev = document.createElement('div');
      newChev.innerText = '$2,950';
      newChev.classList.add('bid-animation__chevron');
      
      bidBtnWrapper.appendChild(newChev);
      
      // counterContainer.innerText = 'Counter: ' + counter;
      // counterContainer.style.display = 'block';
      setTimeout(function() {
        newChev.remove();
      }, 1500);
    });
}