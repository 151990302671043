import axios from 'axios';
import Notification from '../components/notification';

const tWLProcess = document.querySelector('#trWLProcess')?.value;
const tWLDone = document.querySelector('#trWLDone')?.value;

export const addToWatchlist = (inventoryId, el, lang, isTiles, auth, path, page = 1, textValues = {}) => {
    const parentEl = el.parentNode;

    const _loading = () => parentEl.innerHTML = page === 1
        ? `<a class="rs-btn rs-btn_md rs-btn_default rs-btn_with-icon rs-spinner-btn">
            <span class="rs-spinner">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke="#000" cx="10" cy="10" r="9" style="stroke-width: 1px;"></circle>
                </svg>
            </span>
            <span>${tWLProcess}...</span>
        </a>`
        : `<a class="rs-btn rs-btn_sm rs-btn_default rs-btn_with-icon rs-spinner-btn">
            <span class="rs-spinner">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke="#000" cx="10" cy="10" r="9" style="stroke-width: 1px;"></circle>
                </svg>
            </span>
            <span>${tWLProcess}...</span>
        </a>`;
    const _success = () => {
        parentEl.innerHTML = page === 1
            ? `<a class="rs-btn rs-btn_md rs-btn_default rs-btn_with-icon" href="./myaccount/watchlist">
                 <span>
                    <svg class="s-icon" style="--color-icon-mono:var(--color-brand);fill:var(--color-brand);">
                        <use xlink:href="/images/icons/icons-sprite.svg#like-icon"></use>
                    </svg>
                </span>
                <span>${tWLDone}</span>
            </a>`
            : `<a class="rs-btn rs-btn_sm rs-btn_default rs-btn_with-icon" href="./myaccount/watchlist">
                <span>
                    <svg class="s-icon" style="--color-icon-mono:var(--color-brand);fill:var(--color-brand);">
                        <use xlink:href="/images/icons/icons-sprite.svg#like-icon"></use>
                    </svg>
                </span>
                <span>${tWLDone}</span>
            </a>`;
    }

    if (!auth) {
        window.location.href = path;
    }

    if (!isTiles)
        _loading()

    // const proxyUrl = '/api/watchlist/set?inventoryid=' + inventoryId + '&nr=1';
    setTimeout(() => axios.get(`/api/watchlist/set?inventoryid=${inventoryId}&nr=1`)
        .then(res => {
            res?.data?.isSuccess
                ? _success()
                : _loading()
        })
        .catch(err => {
            _loading()
        }), 1000)

}

export const addToWatchlistNew = (inventoryId, el, lang, isTiles, auth, path, page = 1, textValues = {}, tileType = 2) => {
    const parentEl = el.parentNode;

    const _loading = () => parentEl.innerHTML = tileType === 2
        ? `<div class="home-vehicle__wl rs-spinner-btn js-add-to-watchlist" data-inventoryid="@item.InventoryId">
                <span class="rs-spinner">
                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <circle fill="none" stroke="#fff" cx="10" cy="10" r="9" style="stroke-width: 1px;"></circle>
                    </svg>
                </span>
            </div>`
        : `<div class="rs-btn rs-btn_sm rs-btn_default rs-btn_with-icon rs-spinner-btn js-add-to-watchlist" data-inventoryid="@item.InventoryId">
            <span class="rs-spinner">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke="#000" cx="10" cy="10" r="9" style="stroke-width: 1px;"></circle>
                </svg>
            </span>
            <span>Watch item</span>
        </div>`;
    const _success = () => {
        parentEl.innerHTML = tileType === 2
            ? `<a class="home-vehicle__wl js-remove-from-watchlist" data-inventoryid="${inventoryId}">
                    <span>
                        <svg class="s-icon" style="--color-icon-mono:var(--color-brand);fill:var(--color-brand);">
                            <use xlink:href="/images/icons/icons-sprite.svg#like-icon"></use>
                        </svg>
                    </span>
                </a>`
            : `<div class="rs-btn rs-btn_sm rs-btn_default rs-btn_with-icon js-add-to-watchlist" data-inventoryid="@item.InventoryId">
                    <span>
                    <svg class="s-icon" style="--color-icon-mono:var(--color-brand);fill:var(--color-brand);">
                        <use xlink:href="/images/icons/icons-sprite.svg#like-icon"></use>
                    </svg>
                </span>
                <span>${tWLDone}</span>
            </div>`;

        // Event listener for the dynamically added element
        const removeButton = parentEl.querySelector('.js-remove-from-watchlist');
        if (removeButton) {
            removeButton.addEventListener('click', function (event) {
                const btn = event.target.closest('.js-remove-from-watchlist');
                removeFromWatchlistNew(inventoryId, btn, isTiles);
            });
        }

        const badge = document.querySelector('.badge.badge-warning');
        if (badge) {
            let value = parseInt(badge.textContent);
            value++;
            badge.textContent = value;
        }
    }

    if (!auth) {
        window.location.href = path;
    }

    if (!isTiles)
        _loading()

    // const proxyUrl = '/api/watchlist/set?inventoryid=' + inventoryId + '&nr=1';
    setTimeout(() => axios.get(`/api/watchlist/set?inventoryid=${inventoryId}&nr=1`)
        .then(res => {
            res?.data?.isSuccess
                ? _success()
                : _loading()
        })
        .catch(err => {
            _loading()
            console.error(err)
        }), 1000)

}

export const removeFromWatchlistNew = async (id, el, isTiles) => {
    const parentEl = el.parentNode;
    const _loading = () => parentEl.innerHTML = `
        <div class="home-vehicle__wl rs-spinner-btn js-add-to-watchlist" data-inventoryid="@item.InventoryId">
            <span class="rs-spinner">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke="#fff" cx="10" cy="10" r="9" style="stroke-width: 1px;"></circle>
                </svg>
            </span>
        </div>`;

    const _success2 = () => {
        parentEl.innerHTML = `
        <div class="home-vehicle__wl js-add-to-watchlist" data-inventoryid="${id}">
            <span>
                <svg class="s-icon icon-nofill" style="stroke: #fff;">
                    <use xlink:href="/images/icons/icons-sprite.svg#like-icon"></use>
                </svg>
            </span>
        </div>`;

        // Event listener for the dynamically added element
        const addButton = parentEl.querySelector('.js-add-to-watchlist');
        if (addButton) {
            addButton.addEventListener('click', function (event) {
                
                addToWatchlistNew(id, addButton, 'en', false, true, '/', 0);
                console.log('Added to WL', id);
            });
        }

    }
    if (!isTiles)
        _loading()

    setTimeout(() =>
        axios.get(`./myaccount/watchlistdeleteitem`, { params: { inventoryid: id } })
            .then(res => {
                // If not signin
                if (res?.status === 401) return window.location.href = `./login`;

                // If item successfully removed
                if (res?.data?.isSuccess) {
                    _success2();
                    const badge = document.querySelector('.badge.badge-warning');
                    if (badge) {
                        let value = parseInt(badge.textContent);
                        value--;
                        badge.textContent = value;
                    }
                }
                else {
                    _loading();
                }
            })
            .catch(err => {
                console.error(err);
                _loading();

            }), 500);
}

export const countryChange = async (c) => {
    let _states = []
    await axios.get(`/countrystate/getstates/${c}`)
        .then(res => _states = res?.data)
        .catch(err => console.error(err))
    return _states
}

export const checkInternetConnection = () => {
    const _notify = new Notification({
        msg: 'Internet connection lost.'
    });

    window.addEventListener('offline', () => {
        _notify.open();
    });
    window.addEventListener('online', () => {
        _notify.close();
    });
}

export const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check common mobile identifiers and exclude tablets explicitly
    return /iphone|android.+mobile/i.test(userAgent.toLowerCase());
}

export const getImageBySize = (urlString, size) => {
    const urlInited = new URL(urlString);
    const pathArr = urlInited.pathname.split('/');
    pathArr[pathArr.length - 1] = size;
    const newPath = pathArr.join('/');
    urlInited.pathname = newPath;
    const imageUrl = urlInited.href;

    return imageUrl;
}

