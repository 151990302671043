export default class Format {
    // Helper function to create a formatter based on language
    createFormatter(lang) {
        const locales = {
            en: 'en-US',
            ru: 'ru-RU',
            es: 'es-MX',
            ua: 'uk-UA',
            de: 'de-DE'
        };
        return new Intl.NumberFormat(locales[lang] || 'de-DE', {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        });
    }

    // Format: $2,000 USD
    money(value, lang) {
        const formatter = this.createFormatter(lang);
        return `$${formatter.format(value)}`;
    }

    // Format: 2,000
    moneyNC(value, lang) {
        const formatter = this.createFormatter(lang);
        return `${formatter.format(value)}`;
    }

    // Format: $2000
    moneySimple(value) {
        const formatter = this.createFormatter('en'); // Default to 'en' for simplicity
        return `$${formatter.format(value)}`;
    }

    // Format: 2,000
    number(value, lang) {
        const formatter = this.createFormatter(lang);
        return `${formatter.format(value)}`;
    }

    // Only num Input
    onlyNum(element) {
        if (element) {
            element.addEventListener('input', (e) => {
                element.value = e.target.value.replace(/[^0-9]/g, '');
            });
        }
    }
}
