<template>
    <div>
        <div class="dropdown settings-dropdown">
            <span class="settings" type="button" data-toggle="dropdown" rel="nofollow" aria-label="Settings">
                <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-icon"><path fill="currentColor" d="M444.788 291.1l42.616 24.599c4.867 2.809 7.126 8.618 5.459 13.985-11.07 35.642-29.97 67.842-54.689 94.586a12.016 12.016 0 0 1-14.832 2.254l-42.584-24.595a191.577 191.577 0 0 1-60.759 35.13v49.182a12.01 12.01 0 0 1-9.377 11.718c-34.956 7.85-72.499 8.256-109.219.007-5.49-1.233-9.403-6.096-9.403-11.723v-49.184a191.555 191.555 0 0 1-60.759-35.13l-42.584 24.595a12.016 12.016 0 0 1-14.832-2.254c-24.718-26.744-43.619-58.944-54.689-94.586-1.667-5.366.592-11.175 5.459-13.985L67.212 291.1a193.48 193.48 0 0 1 0-70.199l-42.616-24.599c-4.867-2.809-7.126-8.618-5.459-13.985 11.07-35.642 29.97-67.842 54.689-94.586a12.016 12.016 0 0 1 14.832-2.254l42.584 24.595a191.577 191.577 0 0 1 60.759-35.13V25.759a12.01 12.01 0 0 1 9.377-11.718c34.956-7.85 72.499-8.256 109.219-.007 5.49 1.233 9.403 6.096 9.403 11.723v49.184a191.555 191.555 0 0 1 60.759 35.13l42.584-24.595a12.016 12.016 0 0 1 14.832 2.254c24.718 26.744 43.619 58.944 54.689 94.586 1.667 5.366-.592 11.175-5.459 13.985L444.788 220.9a193.485 193.485 0 0 1 0 70.2zM336 256c0-44.112-35.888-80-80-80s-80 35.888-80 80 35.888 80 80 80 80-35.888 80-80z"></path></svg>
            </span>
            <ul class="dropdown-menu">
                <li>
                    <a href="#" rel="nofollow" id="settingTimeZone" class="setting-modal" @click="openModalHandler('timeZone', $event)">
                        <span>Time Zone: {{lblTimeZoneHeader}}</span>
                    </a>
                </li>
                <li><a href="#" rel="nofollow" id="settingLanguage" class="setting-modal" @click="openModalHandler('language', $event)"><span>Language</span></a></li>

                <li><a href="#" rel="nofollow" id="settingOffice" class="setting-modal" v-on:click="openModalHandler('office', $event)"><span>Office / Location</span></a></li>

                <li v-if="isAuth" class="divider"></li>
                <li v-if="isAuth"><a id="hplSettingSignOut" rel="nofollow" href="./signout">Log Out</a></li>
                
            </ul>
        </div>

        <div id="layoutModal" class="rs-modal n-modal" style="position:absolute;z-index:102" aria-hidden="true" ref="layoutModal">
            <div class="modal__overlay js-n-modal__overlay" tabindex="-1">
                <div class="modal__container rs-modal__settings" role="dialog" aria-modal="true" aria-labelledby="layoutModal-title">
                    <header class="modal__header">
                        <h3 v-if="modalOpen === 1">Time Zone Setting</h3>
                        <h3 v-if="modalOpen === 2">Language Setting</h3>
                        <h3 v-if="modalOpen === 3">Office Setting</h3>
                        <button class="modal__close js-n-modal__close" aria-label="Close modal"></button>
                    </header>
                    <div class="modal__body">
                        <div v-if="modalOpen === 1" class="text-left">

                            <p>
                                <select class="form-control" v-model="timeZone.currentTimeZoneId">
                                    <option :key="t" v-for="t in timeZone.timeZones" :value="t.timeZoneId">{{t.displayName}}</option>
                                </select>
                            </p>
                            <div class="btn-block">
                                <button id="aTimeZoneSave" class="rs-btn rs-btn_md rs-btn_primary" @click="saveOptionHandler('timeZone')">Save</button>
                            </div>
                        </div>
                        <div v-if="modalOpen === 2" class="text-left">
                            <p>
                                <select class="form-control" v-model="language.currentLanguage">
                                    <option :key="l" v-for="l in language.languages" :value="l.languageCode">{{l.caption}}</option>
                                </select>
                            </p>
                            <div class="btn-block"><button @click="saveOptionHandler('lang')" class="rs-btn rs-btn_md rs-btn_primary">Save</button></div>
                        </div>
                        <div v-if="modalOpen === 3" class="text-left">

                            <p>
                                <select class="form-control" v-model="office.currentOfficeId">
                                    <option :key="o" v-for="o in office.offices" :value="o.officeLocationId">{{o.country}}</option>
                                </select>
                            </p>
                            <div class="btn-block"><button @click="saveOptionHandler('office')" class="rs-btn rs-btn_md rs-btn_primary">Save</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="rs-modal n-modal rs-calculate" style="position: absolute;" id="calcModal" aria-hidden="true">
            <div class="modal__overlay js-n-modal__overlay" tabindex="-1">
                <div class="modal__container" style="position: relative;" role="dialog" aria-modal="true" aria-labelledby="calcModal-title">
                    <header class="modal__header">
                        <div></div>
                        <button class="modal__close js-n-modal__close" aria-label="Close modal"></button>
                    </header>
                    <div class="modal__body">
                        <keep-alive>
                            <div id="calcInv" >
                                <calculator></calculator>
                            </div>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>  
        
    </div>
</template>

<script>

import { Toast } from '../components/toast';
import { rsTranslator } from '../mixins/translate';
import '../components/modal';
import '../components/topNotification';
import '../components/dropdown';
import * as signalR from '@microsoft/signalr';
import gsap from "gsap";
import rsHelper from '../RSHelpers';
import Format from "../utilities/formats";
import axios from 'axios';
import CountdownSearch from "../components/countdownSearch";
import { isDevice } from '../utilities/helpers';
import calculator from './calculator.vue';

const LANG = document.querySelector('#rsLang').value;
const OFFICE = document.querySelector('#rsOfficeId').value;
const TIMEZONE = document.querySelector('#rsTimeZoneId').value;
const LblTimeZoneHeader = document.querySelector("#lblTimeZoneHeader").value;
const ISAUTH = document.querySelector("#rsUsrAuth").value;

export default {
  components: { calculator },
    name: "Header",
    mixins: [rsTranslator ],
    data() {
        return {
            input: '',
            modalOpen: null,
            limit: {
                isShowDepositInfo: false,
                isShowWireInfo: false,
                desiredLimit: 500,
                wireInfo: {},
                requiredDeposit: {},
                ccPaymentUrl: "",
            },
            
            office: {
                offices: [],
                currentOfficeId: OFFICE
            },
            timeZone: {
                timeZones: [],
                currentTimeZoneId: TIMEZONE
            },
            language: {
                languages: [],
                currentLanguage: LANG
            },
            connection: null,
            prevSum: null,
            lblTimeZoneHeader: LblTimeZoneHeader,
            isAuth: false
        }
    },
    created: async function () {
        if (LANG != "en") {
            await this.getTranslateData(LANG);
        }
        this.isAuth = ISAUTH == "true" ? true : false;
        const wlActive = document.getElementById('hdnWLActiveItems');
        if (wlActive) {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    /*console.log(mutation.target.value);*/
                    if (this.connection) {
                        this.connection.stop();
                    }
                    if (document.getElementById("hdnStatisticAuctionIds")) {
                        this.signalRInit();
                    }
                        this.timeRunHelper();
                });
            });
            const observerConfig = { attributes: true, attributeOldValue: true };
            observer.observe(wlActive, observerConfig);
        }

        // Experimental
        const theme = localStorage.getItem('rsTheme') || 'theme-7';
        this.theme = theme;
        document.documentElement.setAttribute('data-theme', theme);

        // Set avatar with name
        const userName = document.querySelector('.js-nav-user__name')?.dataset.name;
        const userLastName = document.querySelector('.js-nav-user__name')?.dataset.lastname;

        if (userName) {
            const avatar = document.querySelector('.js-nav-link__user');

            const getFirstLetter = (name) => {
                return name !== undefined ? name[0].toUpperCase() : '';
            };
            avatar.querySelector('span').innerText = getFirstLetter(userName) + getFirstLetter(userLastName);
            avatar.querySelector('span').style.opacity = 1;
        }
    },
    mounted: function() {
        // Init mob navigation
        this.mobNavInit();

        // Init search block
        this.searchBlockInit();

        // Init user menu
        this.userMenuInit();

        if (document.getElementById("hdnStatisticAuctionIds")) {
          this.signalRInit();
        }
        this.timeRunHelper();

        // Experimental
        document.querySelectorAll('.js-nav-exp').forEach(el => {
            el.dataset.theme == this.theme?.split('-')[1] 
                ? el.classList.add('active') 
                : el.classList.remove('active');
        });

        this.themeHandlerEvent();

        // Prevent scrolling on search input
        document.querySelector('.js-search-h__input')?.addEventListener('focus', () => {
            document.querySelector('.js-search-h__input').scrollIntoView(false);
        });
    },
    methods: {
        formatMoney(val, lang) {
            if (!val) return;
            const _moneyF = new Format;
            return _moneyF.money(val, lang)
        },
        mobNavInit() {
            // Navbar
            const navbarToggle = document.querySelector('.js-menu-btn')
            const navbarMenu = document.querySelector('.js-navbar-mob');
            const menuNavButtons = document.querySelectorAll('.js-menu-nav__btn');

            navbarToggle.addEventListener('click', (e) => {
                e.preventDefault();

                navbarToggle.classList.toggle('open');
                navbarMenu.classList.toggle('open');
            })

            // Submenu
            const itemCollapse = document.querySelectorAll('.js-menu-item__collapse');

            itemCollapse.forEach(item => {
                item.addEventListener('click', (e) => {
                    e.preventDefault();
                    item.closest('.dropdown').classList.toggle('open');
                });
            });

            // Menu nav items
            if (menuNavButtons) {
                menuNavButtons.forEach(btn => {
                    btn.addEventListener('click', (e) => {
                        e.preventDefault();
                        const type = btn.dataset.type;

                        this.openModalHandler(type, e);
                        
                        // Close menu
                        navbarToggle.classList.toggle('open');
                        navbarMenu.classList.toggle('open');
                    });
                });
            }
        },
        searchBlockInit() {
            if (document.querySelector('.js-search-panel__btn')) {
                const searchPanelButton = document.querySelector('.js-search-panel__btn');
                const searchPanelClose = document.querySelector('.js-srch-close');
                const headerSearchButton = document.querySelector('.js-hdr-search');
                const searchFilterButton = document.querySelector('.js-srch-filter-btn');
                const headerSearchBackdrop = document.querySelector('.js-search-h__backdrop');

                const chevronButton = document.querySelector('.js-srch-filter-chevron');
            
                const openSearchPanel = () => {
                    const searchPanel = document.querySelector('.js-srch-panel');
            
                    setTimeout(() => {
                        document.querySelector('.js-search-h__input').focus({ preventScroll: true, focusVisible: true });
                    }, 100);
                    searchPanel.style.display = 'block';
                    searchPanel.classList.toggle('open');
                }
            
                searchPanelButton.addEventListener('click', (e) => {
                    e.preventDefault();
                    openSearchPanel();
                });
            
                headerSearchButton.addEventListener('click', (e) => {
                    e.preventDefault();
                    openSearchPanel();
                });
            
                const closeHandler = (e) => {
                    const searchPanel = document.querySelector('.js-srch-panel');
                    if (!searchPanel.contains(e.target)) {
                        searchPanel.style.display = 'none';
                        searchPanel.classList.remove('open');
                    }
                    searchPanel.style.display = 'none';
                    searchPanel.classList.remove('open');
                }
            
                searchPanelClose.addEventListener('click', (e) => closeHandler(e));
                searchPanelClose.removeEventListener('click', (e) => closeHandler(e));
                headerSearchBackdrop.addEventListener('click', (e) => closeHandler(e));
                headerSearchBackdrop.removeEventListener('click', (e) => closeHandler(e));
            
                const setSearchButtonPosition = () => {
                    if (window.scrollY > 57) {
                        showHeaderSearchButton();
                    }
                }
            
                window.addEventListener('load', () => setSearchButtonPosition());
                window.removeEventListener('load', () => setSearchButtonPosition());
            
                const showHeaderSearchButton = () => {
                    headerSearchButton.classList.add('show');
                    headerSearchButton.style.display = 'block';
                    // Toggle header border
                    document.querySelector('.js-header').style.borderBottom = '1px solid #e5e5e5';
                }
                const hideHeaderSearchButton = () => {
                    headerSearchButton.classList.remove('show');
                    headerSearchButton.style.display = 'none';
                    // Toggle header border
                    document.querySelector('.js-header').style.borderBottom = 'none';
                }
            
                const intersectionCallback = (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            hideHeaderSearchButton();
                        } else {
                            showHeaderSearchButton();
                        }
                    });
                };
            
                let options = {
                    rootMargin: "0px",
                    threshold: 1.0,
                };
                  
                let observer = new IntersectionObserver(intersectionCallback, options);
                observer.observe(searchPanelButton);

                const asideSearchDesk = document.querySelector('.js-aside-search__desk');

                if (!asideSearchDesk) {
                    searchFilterButton.style.display = 'none';
                    return;
                }

            
                if (searchFilterButton) {
                    searchFilterButton.addEventListener('click', (e) => {
                        searchFilterButton.classList.toggle('open');
                        const searchFilter = document.querySelector('.js-aside-mobile');
                        searchFilter.classList.toggle('open');

                        chevronButton.classList.toggle('open');
                    });
                }
            }
            
        },
        userMenuInit() {
            // User Menu
            const userNavButton = document.querySelector('.js-nav-link__user');
            const userPopup = document.querySelector('.js-nav-pp-user');
            // const watchlistNavButton = document.querySelector('.js-nav-link__watchlist');
            // const watchlistPopup = document.querySelector('.js-nav-pp-watchlist');
            const supportNavButtons = document.querySelectorAll('.js-navbar-support');
            const supportPopup = document.querySelector('.js-nav-pp-support');
            const headerIncrLimitBtn = document.querySelector('.js-increase-limit-handler');
            const headerIncrLimitBtnDesk = document.querySelector('.js-increase-limit');

            if (userNavButton) {
                userNavButton.addEventListener('click', (e) => {
                    e.preventDefault();
                    userPopup.classList.toggle('open');

                    setTimeout(() => {
                        userPopup.querySelector('.navbar-popup__container ').style.transform = 'translateX(0)';
                    }, 200);

                    const backDrop = userPopup.querySelector('.js-nav-pp-backdrop');
                    backDrop.addEventListener('click', (e) => {
                        e.preventDefault();
                        userPopup.querySelector('.navbar-popup__container ').style.transform = 'translateX(100%)';

                        setTimeout(() => {
                            userPopup.classList.remove('open');
                        }, 200);
                    });
                });
            };

            if (supportNavButtons.length) {
                supportNavButtons.forEach(supportNavButton => {
                    supportNavButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        supportPopup.classList.toggle('open');
                        const closeBtn = supportPopup.querySelector('.js-support-close');
                        closeBtn.addEventListener('click', (e) => {
                            e.preventDefault();
                            supportPopup.classList.remove('open');
                        });
                    });
                });
            }

            if (headerIncrLimitBtn) {
                headerIncrLimitBtn.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.increaseLimitHandler();
                });
            }

            if (headerIncrLimitBtnDesk) {
                headerIncrLimitBtnDesk.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.increaseLimitHandler();
                });
            }
        },
        increaseLimitHandler() {
            showModal('calcModal');
        },
        closeModalHandler() {
            this.modalOpen = null
            hideModal('layoutModal');
        },
        openModalHandler(type, event) {
            event.preventDefault();
            showModal('layoutModal');
            if (type === 'office') {
                this.modalOpen = 3
                this.getOffices()
            } else if (type === 'timeZone') {
                this.modalOpen = 1
                this.getTimeZone()
            } else if (type === 'language') {
                this.modalOpen = 2
                this.getLanguages()
            }
        },
        // Save setting option Office or TimeZone
        saveOptionHandler(option) {
            if(option === 'lang') {
                const pathUrl = window.location.pathname;
                const splitUrl = pathUrl.split('/');
                const params = new URLSearchParams(window.location.search);
                var newSplitUrl = [];

                const isLang = (value) => {
                    return this.language.languages.find(lang => lang.languageCode === value);
                }

                const curLang = this.language.languages.find(lang => lang.languageCode === this.language.currentLanguage)

                if (curLang) {

                    axios.get(`/${curLang.languageCode}/language/setcustomerlanguage?languageId=${curLang.siteLanguageId}&setlang=1`)
                        .then(res => {

                            if (res?.data?.isSuccess) {
                                this.closeModalHandler()

                                if (this.language.languages.filter(lang => lang === splitUrl[1]) && isLang(splitUrl[1])) {

                                    if (this.language.currentLanguage == res?.data?.data) {

                                        newSplitUrl = splitUrl.splice(1, 1);
                                        //alert(splitUrl[1] + ' 11 ' + newSplitUrl);
                                    }
                                    else
                                        newSplitUrl = splitUrl.splice(1, 1, this.language.currentLanguage);
                                } else {
                                    //alert(splitUrl[1] + ' 2 ' + this.language.currentLanguage);
                                    newSplitUrl = splitUrl.splice(0, 1, this.language.currentLanguage);
                                }
                                //console.log(splitUrl);
                                var newPathUrl = splitUrl.join('/');
                                //alert(splitUrl[1] + ' ' + this.language.currentLanguage);
                                if (newPathUrl == '') {
                                    newPathUrl = '/';
                                } else if (params != '') {
                                    newPathUrl += '?' + params;
                                }
                                window.location.href = newPathUrl;
                            } else {
                                new Toast({
                                    message: `<h3 class="rs-mb-2">WARNING</h3> ${res.data.message}.`,
                                    type: 'danger',
                                });
                                window.location.href = ".";
                            }
                        })
                        .catch(err => {
                            console.error(err)
                        })
                    return;
                }
            } else if(option === 'timeZone') {
                const timeZone = this.timeZone.timeZones.find(t => t.timeZoneId == this.timeZone.currentTimeZoneId);
                const _data = {
                    TimeZoneID: this.timeZone.currentTimeZoneId,
                    WindowTimeZoneId: timeZone.windowsTimeZoneId
                };
                const proxyUrl = `/timezone/savecustomertimezone?timeZoneId=${this.timeZone.currentTimeZoneId}&timeZoneWindowsId=${timeZone.windowsTimeZoneId}`;
                this.closeModalHandler();
                fetch(proxyUrl).then(function (r) {
                    // console.log(r)
                    window.location.reload(false);
                    
                });
            } else if(option === 'office') {
                
                const proxyUrl = `/account/savecustomerofficelocation?officeId=${this.office.currentOfficeId}`;
                this.closeModalHandler();
                fetch(proxyUrl).then( function (data) {
                    // console.log(data)
                    window.location.reload(false);
                    
                });
            }
        },
        async getOffices() {
            var url = `${_apiPath}/OfficeLocation/Get?nr=1`;
            const _data = await fetch(url).then(res => res.json())

            this.office.offices = _data;
        },
        async getTimeZone() {
            var url = `${_apiPath}/TimeZone/GetTimeZones?nr=1`;
            const _data = await fetch(url).then(res => res.json())
            this.timeZone.timeZones = _data;
        },
        async getLanguages() {
            var url = `${_apiPath}/Language/GetLanguages?nr=1`;
            const _data = await fetch(url).then(res => res.json())
            this.language.languages = _data;
        },
        async signalRInit() {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(signalRHubUrl)
                .withAutomaticReconnect()
                .configureLogging(signalR.LogLevel.Information)
                .build();

            var auctionIds = rsHelper.getValue(localPrefix + 'hdnStatisticAuctionIds');
            if (!auctionIds) {
                return;
            }
            try {
                await this.connection.start();
                console.log("connected");
            } catch (err) {
                console.log(err);
                setTimeout(() => start(), 5000);
            }
            this.connection.invoke("MultipleLanes", auctionIds).then(function () {
                console.log("send GetCurrentBidInfo");
            }).catch(err => console.error(err.toString()));

            this.connection.on("BidInfo", this.bidInfo);

        },
        bidInfo: async function (bidInfoModel) {
            const self = this
            var id = bidInfoModel.lot;
            var statElems = document.querySelectorAll(`[data-said="${bidInfoModel.said}"]`); 

            statElems.forEach(e =>
            {
                var laneRunId = e.dataset.run;
                var statusBlock = e.querySelector(`[data-status]`);
                var run = laneRunId - bidInfoModel.rsr;//"This item is next!"
                var statusText = "";
                if (run < 0) {
                    statusText = this.t(781, 'This item already sold.');
                    var soldBlock = e.querySelector(`[data-bg]`);
                    if (soldBlock) {
                        soldBlock.classList.add("label-sold");
                    }
                    var curBidBlock = e.querySelector(`[data-curBid]`);
                    if (curBidBlock) {
                        curBidBlock.innerText = 'FINAL BID'
                    }
                } else if (run == 0) {
                    statusText = this.t(783, 'Selling now...') ;
                } else if (run == 1) {
                    statusText = rsHelper.getValue('hdnABNext');
                }
                else if (run > 1) {
                    statusText = run + ' ' + rsHelper.getValue('hdnABToGo');
                }

                if (statusBlock) {
                    statusBlock.innerText = ` ${statusText}`;
                }
                    
            });

            const elem = document.querySelector(`[data-lot="${id}"]`);
            if (elem) {
                const priceBlock = elem.querySelector(`[data-price]`);

                const tikAnim = elem.querySelector('.search-block-item-bid-price')
                    ?  gsap.to(`[data-lot="${id}"] span[data-price]`, {
                        ease: "bounce.out",
                            paused: true, 
                            duration: .3,
                            onStart: function() {
                                const target = this.targets()[0];
                                target.style.scale = 1;
                            },
                            onUpdate: function() {
                                const target = this.targets()[0];
                                target.style.scale = 1.4;
                                target.style.fontweight = '700';
                                target.style.color = "#009707";
                            },
                            onComplete: function() {
                                const target = this.targets()[0];
                                target.style.scale = 1;
                                target.style.color = "#1c1e21";
                            }
                        })
                    : gsap.to(`[data-lot="${id}"] span[data-price]`, {
                        ease: "bounce.out",
                        paused: true, 
                        duration: .3,
                        onStart: function() {
                            const target = this.targets()[0];
                            target.style.scale = 1;
                        },
                        onUpdate: function() {
                            const target = this.targets()[0];
                            target.style.scale = 1.25;
                            target.style.color = "#fff";
                        },
                        onComplete: function() {
                            const target = this.targets()[0];
                            target.style.scale = 1;
                        }
                })
                
                
                if (bidInfoModel.sold) {
                    priceBlock.innerText = self.formatMoney(bidInfoModel.cbid, LANG)
                    const soldBlock = elem.querySelector(`[data-bg]`); 
                    if (soldBlock) {
                        soldBlock.classList.add("label-sold");
                        const statusBlock = elem.querySelector(`[data-status]`);
                        // soldBlock.style.background = 'red';
                        if (statusBlock) {
                            statusBlock.innerText = this.t(781, 'This item already sold.');
                        }
                    } 
                    const curBidBlock = elem.querySelector(`[data-curBid]`);
                    if (curBidBlock) {
                        curBidBlock.innerText = 'FINAL BID'
                    }
                } else {
                    if (bidInfoModel.cbid && bidInfoModel.cbid > 0) {
                        // tikAnim.pause();
                        // js-item-price

                        const createPriceBlock = () => {
                            if (elem.querySelector('.sb')) {
                                // elem.querySelector('.js-item-price').style.scale = isDevice() && 1;
                                elem.querySelector('.sb').innerText = self.formatMoney(bidInfoModel.cbid, LANG);

                                return
                            };

                            const pb = document.createElement('span');
                            pb.classList.add('sb');
                            pb.innerText = self.formatMoney(bidInfoModel.cbid, LANG);
                            pb.style.color = '#fff';
                            elem.appendChild(pb);
                        }


                        
                        if (isDevice() && elem.classList.contains('grid-item')   ) {
                            priceBlock.innerText = self.formatMoney(bidInfoModel.cbid, LANG);
                            createPriceBlock();
                        }
                        else {
                            priceBlock.innerText = self.formatMoney(bidInfoModel.cbid, LANG)
                        }
                    
                        const bidAnimateHandler = (element) => {
                            const bidBtnWrapper = element.querySelector('.bid-animation__wrapper')
                            const newChev = document.createElement('div');

                            // Animate price
                            bidBtnWrapper.classList.add('tik')
                            tikAnim.restart();
        
                            // Adding price to chevron
                            newChev.innerText = self.formatMoney(bidInfoModel.cbid, LANG);
                            newChev.classList.add('bid-animation__chevron');
                            bidBtnWrapper.appendChild(newChev);
        
                            setTimeout(function() {
                                newChev.remove();
                                bidBtnWrapper.classList.remove('tik')
                                tikAnim.pause();
                            }, 1500);
                        }
                    
                        if (this.prevSum !== bidInfoModel.cbid) bidAnimateHandler(elem);

                        this.prevSum = bidInfoModel.cbid;
                    }
                }
            }
        },
        async timeRunHelper() {
            var timerunBlocks = document.querySelectorAll(`[data-timerun="true"]`);
            if (timerunBlocks && timerunBlocks.length>0) {
            
                var curDate = document.querySelector("#_CDATE")?.value
                timerunBlocks.forEach(e => {
                    var tdate = e.dataset.tdate;
                    try {
                        var cdAB = new CountdownSearch(e, tdate, curDate);
                        cdAB.init();
                    } catch (err) {
                        console.error(err);
                    }
                })

            }
        },
        // Experimental
        setSiteTheme(e) {
            const theme = Number(e.target.dataset.theme);

            const th = (type) => {
                switch (type) {
                    case type:
                        return `theme-${type}`;
                    default:
                        return 'theme-1';
                }
            }

            // Set to local storage
            localStorage.setItem('rsTheme', th(theme));

            document.documentElement.setAttribute('data-theme', th(theme));

            // Set active class
            document.querySelectorAll('.js-nav-exp').forEach(el => {
                el.dataset.theme == theme ? el.classList.add('active') : el.classList.remove('active');
            });
        },
        themeHandlerEvent() {
            document.querySelectorAll('.js-nav-exp').forEach(el => {
                el.addEventListener('click', (e) => this.setSiteTheme(e));
            });
            // this.setSiteTheme(e);
        }
    },
    computed: {
        inventoryId: function () {
            return miscInventoryId;
        },
        bankWireNote: function () {
            var s = "Buyer Number: " + this.limit.wireInfo.customerId + "; "
            if (this.inventoryId != miscInventoryId)
                s = "Inventory Number: " + this.inventoryId + ";"
            return s;
        }
    }
}

</script>