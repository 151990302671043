
    export function Toast(options) {
    
        if ( !options.message ) {
            throw new Error('Error');
            return;
        }
    
        this.options = options;
        this.options.type = options.type || 'default';
    
        this.toastContainerEl = document.querySelector('.rs-toast-container');
        this.toastEl = document.querySelector('.rs-toast');
        
        this._init();
    }
    
    Toast.prototype._createElements = function() {
        return new Promise((resolve, reject) => {
    
            this.toastContainerEl = document.createElement('div');
            this.toastContainerEl.classList.add('rs-toast-container');
            this.toastContainerEl.setAttribute('role', 'alert');
            this.toastContainerEl.setAttribute('aria-hidden', true); 

            if(!this.options.confirm)
                this.toastContainerEl.classList.add('no-confirm');
    
            this.toastEl = document.createElement('div');
            this.toastEl.classList.add('rs-toast');
    
            this.toastContainerEl.appendChild(this.toastEl);
            document.body.appendChild(this.toastContainerEl);
    
            setTimeout(() => resolve(), 700);
        })
    };
    
    Toast.prototype._addEventListeners = function() {
    
        document.querySelector('.rs-toast-btn--close').addEventListener('click', () => {
            this._close();
        })
        const _confirmBtn = document.querySelector('.rs-toast-btn--confirm')
        if( _confirmBtn ) {
            _confirmBtn?.addEventListener('click', () => {
                this._close();
            })
        }
    
        // if ( this.options.customButtons ) {
        //     const customButtonsElArray = Array.prototype.slice.call( document.querySelectorAll('.rs-toast-btn--custom') );
        //     customButtonsElArray.map( (el, index) => {
        //         el.addEventListener('click', (event) => this.options.customButtons[index].onClick(event) );
        //     });
        // }

        if(!this.options.confirm) {
            setTimeout(() => this._close(), 4000)
        }
    
    };
    
    
    Toast.prototype._close = function() {
        return new Promise((resolve, reject) => {
            this.toastContainerEl.setAttribute('aria-hidden', true); 
            setTimeout(() => {
    
                this.toastEl.innerHTML = '';
                this.toastEl.classList.remove('default', 'success', 'warning', 'danger');
    
                if ( this.focusedElBeforeOpen ) {
                    this.focusedElBeforeOpen.focus();
                }
    
                resolve();
    
            }, 500); 
        });     
    };
    
    Toast.prototype._open = function(type) {
    
        this.toastEl.classList.add(this.options.type);
        this.toastContainerEl.setAttribute('aria-hidden', false); 
    
        let customButtons = '';
        if ( this.options.customButtons ) {
            customButtons = this.options.customButtons.map( (customButton, index) => {
                return `<a type="button" class="rs-toast-btn rs-toast-btn--custom rs-toast-btn--confirm">${customButton.text}</a>`
            } )
            customButtons = customButtons.join('');
        }

        let icon, title;
        switch (this.options.type) {
            case 'warning':
                icon = `
                    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="none"><path stroke="#F60" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M27.011 10.133 4.778 47.25a5.25 5.25 0 0 0 4.488 7.875h44.468a5.25 5.25 0 0 0 4.488-7.875L35.99 10.133a5.25 5.25 0 0 0-8.978 0v0Z"/><path stroke="#F60" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31.5 23.625v10.5M31.5 44.625h.024"/></svg>
                `
                break;
            case 'success':
                icon = `
                <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="none"><path fill="#3AB449" d="M31.5 3.5a28 28 0 1 0 0 56 28 28 0 0 0 0-56Zm0 52.5a24.5 24.5 0 1 1 0-49 24.5 24.5 0 0 1 0 49Z"/><path fill="#3AB449" d="M49 21.175a1.75 1.75 0 0 0-2.467 0L27.108 40.513l-10.5-10.5A1.777 1.777 0 0 0 14 32.428L27.108 45.5 49 23.66a1.75 1.75 0 0 0 0-2.485Z"/></svg>
                `
                break;
            case 'danger':
                icon = `<svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" fill="none"><path stroke="#FF3200" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M31.5 57.75c14.498 0 26.25-11.752 26.25-26.25 0-14.497-11.752-26.25-26.25-26.25-14.497 0-26.25 11.753-26.25 26.25 0 14.498 11.753 26.25 26.25 26.25ZM12.941 12.941 50.06 50.06"/></svg>              
                `
                break;
            default:
                icon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9999 1.33337C9.89026 1.33337 7.82796 1.95896 6.07384 3.13103C4.31972 4.3031 2.95254 5.969 2.14521 7.91808C1.33787 9.86716 1.12664 12.0119 1.53821 14.081C1.94979 16.1501 2.96569 18.0508 4.45745 19.5425C5.94921 21.0343 7.84983 22.0502 9.91896 22.4618C11.9881 22.8733 14.1328 22.6621 16.0819 21.8548C18.031 21.0474 19.6969 19.6802 20.8689 17.9261C22.041 16.172 22.6666 14.1097 22.6666 12C22.6666 9.17106 21.5428 6.45796 19.5424 4.45757C17.542 2.45718 14.8289 1.33337 11.9999 1.33337V1.33337ZM11.9999 21.3334C10.154 21.3334 8.34946 20.786 6.8146 19.7604C5.27974 18.7349 4.08347 17.2772 3.37705 15.5718C2.67063 13.8663 2.4858 11.9897 2.84593 10.1792C3.20606 8.36871 4.09497 6.70567 5.40026 5.40038C6.70555 4.09509 8.36859 3.20617 10.1791 2.84604C11.9896 2.48592 13.8662 2.67075 15.5716 3.37717C17.2771 4.08358 18.7347 5.27986 19.7603 6.81472C20.7859 8.34958 21.3333 10.1541 21.3333 12C21.3333 14.4754 20.3499 16.8494 18.5996 18.5997C16.8492 20.35 14.4753 21.3334 11.9999 21.3334Z" fill="#F2F2F2"/>
                <path d="M18.6667 8.06667C18.5418 7.9425 18.3728 7.8728 18.1967 7.8728C18.0206 7.8728 17.8516 7.9425 17.7267 8.06667L10.3267 15.4333L6.32671 11.4333C6.20471 11.3016 6.03538 11.2237 5.85597 11.2169C5.67656 11.21 5.50176 11.2747 5.37004 11.3967C5.23831 11.5187 5.16045 11.688 5.15357 11.8674C5.1467 12.0468 5.21137 12.2216 5.33337 12.3533L10.3267 17.3333L18.6667 9.01333C18.7292 8.95136 18.7788 8.87762 18.8126 8.79638C18.8465 8.71514 18.8639 8.62801 18.8639 8.54C18.8639 8.45199 18.8465 8.36485 18.8126 8.28361C18.7788 8.20237 18.7292 8.12864 18.6667 8.06667Z" fill="#F2F2F2"/>
                </svg>
                `
        }
        

        this.toastEl.innerHTML = this.options.cunfirm
            ? `<div class="rs-toast__icon-block">
                        ${icon}
                    </div>
                    <div class="rs-toast__content">
                        ${this.options.message}
                    </div>
                    <div class="rs-toast__btns">    
                        <div type="button" class="rs-toast-btn rs-toast-btn--close">close</div>
                        ${customButtons}
                    </div>
                `
            : `<div class="rs-toast__icon-block">
                    ${icon}
                </div>
                <div class="rs-toast__content">
                    ${this.options.message}
                </div>
                <div class="rs-toast__btns only">    
                    <div type="button" class="rs-toast-btn rs-toast-btn--close">close</div>
                </div>
            `
    
        this.focusedElBeforeOpen = document.activeElement;
        document.querySelector('.rs-toast-btn--close').focus();
    };
    
    Toast.prototype._init = function() {
        Promise.resolve()
        .then(() => {
            if ( this.toastContainerEl ) { 
                return Promise.resolve();
            }
            return this._createElements();
        })
        .then(() => {
            if ( this.toastContainerEl.getAttribute('aria-hidden') == 'false'  ) {
                return this._close();
            }
            return Promise.resolve();
        })
        .then(() => {
            this._open();
            this._addEventListeners();
        })
    };